import { faClipboardCheck, faExclamationTriangle, faMinusSquare, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Col, Form, FormControl, Row, Table } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { getCodeTable, sendHttpRequest } from '../../utils/apiUtils';
import { USER_KEY } from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';

class ProductOrder extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedProducts: [],
            asyncSelectValue: undefined,
            error: {},
        }

        this.orderType = props.orderType
        this.user = JSON.parse(sessionStorage.getItem(USER_KEY))
    }

    searchProducts = (inputValue, callback) => {

        let result = []

        if (this.allProducts === undefined) {
            getCodeTable("/api/wrh/products/allCountable", this.user, (opt) => {
                this.allProducts = opt
                callback(opt)
            })
        }

        if (inputValue === undefined || inputValue === null || inputValue === '') {
            result = this.allProducts
        } else {
            const regex = new RegExp(inputValue, "i");
            result = this.allProducts.filter(el => {
                return (el.barcode === inputValue || regex.test(el.name) || regex.test(el.barcode))
            })
        }

        callback(result);
    }

    addProduct = (prd) => {
        if (!this.state.selectedProducts.includes(prd)) {
            this.setState(prevState => {

                return {
                    selectedProducts: [...prevState.selectedProducts, prd],
                    asyncSelectValue: null
                }
            })
        }
    }

    removeProduct = (e, prd) => {
        if (this.state.selectedProducts.includes(prd)) {
            this.setState(prevState => {
                const result = prevState.selectedProducts.filter((e, i) => e !== prd)
                return {
                    selectedProducts: result
                }
            })
        }
    }

    submitOrder = async (e) => {
        let hasErrors = false

        if (this.state.selectedProducts.length === 0) {
            return
        }

        this.state.selectedProducts.map((p) => {
            p.toAdd = parseInt(p.toAdd)
            if (isNaN(p.toAdd) || (p.toAdd === 0 && this.orderType !== 'overhaul')) {
                p.error = "error"
                hasErrors = true
            } else {
                delete p.error
            }

            return false
        })

        if (hasErrors) {
            // toast.error("Please fix the errors", { autoClose: false, onClose: (e) => { } })
            this.setState(prevState => ({ selectedProducts: this.state.selectedProducts }))
        } else {
            toast.dismiss()
            const reqBody = {
                totalAmount: 0,
                paymentType: { id: 1 },
                orderItems: []
            }

            this.state.selectedProducts.map((p) => 
                reqBody.orderItems.push({
                    orderId: 0,
                    productId: p.id,
                    quantity: p.toAdd,
                    discount: 0,
                })
            )

            const URL = (() => {
                switch (this.orderType) {
                    case 'delivery':
                        return '/api/wrh/orders/delivery';
                    case 'writeOut':
                        return '/api/wrh/orders/writeOut'
                    case 'overhaul':
                        return '/api/wrh/orders/overhaul'
                    default:
                        return 'Невалидна операция';
                }
            })();

            await sendHttpRequest(URL, "POST", reqBody, this.user, 8000).then(async response => {

                var msg = "Грешка при работа с базите данни"
                var result = await response.json()

                switch(response?.status) {
                    case 200:
                        this.setState({ ...this.state, selectedProducts: [] })
                        toast.success('Данните са запазени', {
                                onOpen: (e) => { },
                                autoClose: 500,
                                onClose: () => { window.location.href = "/products" }
                        });
                        return;
                    case 499:
                        msg = "Времето за отговор изтече. Моля проверете резултата"
                        break;
                    case 500:
                        msg = result?.message ?? msg
                        break;
                    default:
                }

                toast.error(msg, {
                    onOpen: (e) => { },
                    autoClose: false,
                });
            })
        }
    }

    getOrderType(param) {
        switch (this.orderType) {
            case 'delivery':
                return { title: 'Доставка', btnIcon: faTruck }
            case 'writeOut':
                return { title: 'Изписване', btnIcon: faMinusSquare }
            case 'overhaul':
                return { title: 'Ревизия', btnIcon: faClipboardCheck }
            default:
                return { title: 'Невалидна операця', btnIcon: faExclamationTriangle };
        }
    }

    render() {
        return (<>
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <h3>{this.getOrderType().title}</h3>
                    <Row>
                        <Col sm="10">
                            <AsyncSelect className="text-body" name="product" loadOptions={this.searchProducts.bind(this)} defaultOptions value={this.state.asyncSelectValue}
                                onChange={this.addProduct.bind(this)} isSearchable={true} placeholder="Изберете продукт" />
                        </Col>
                        <Col sm="2">
                            <ActionButton variant="success" type="button" disabled={this.state.selectedProducts.length === 0} onClick={this.submitOrder.bind(this)} >
                                <FontAwesomeIcon icon={this.getOrderType().btnIcon} /> Запази</ActionButton>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label className='mt-4 mx-3' >Коментар</Form.Label>
                        <Col sm="12" className=''>
                            <FormControl as='textarea' />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover variant="dark-dpool" className="product-delivery-table" responsive>
                        <colgroup>
                            <col span={5} />
                            <col span={1} style={{ width: "5%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className='col-sm-3'>Име</th>
                                <th className='col-sm-2'>Barcode</th>
                                <th className='col-sm-1'>Цена</th>
                                <th className='col-sm-1'>Налични</th>
                                <th className='col-sm-2'>Брой</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.selectedProducts?.map((p) => (
                                <tr key={p.id} >
                                    <td>{p.name}</td>
                                    <td>{p.barcode}</td>
                                    <td>{p.priceSell}</td>
                                    <td style={(p.quantity < 1) ? { color: 'red' } : {}}>{p.quantity}</td>
                                    <td><FormControl type="number" min={0} className={p.error ? 'missing-quantity' : ''}
                                        onChange={(e) => { p.toAdd = e.target.value; e.target.classList.remove("missing-quantity") }} /></td>
                                    <td style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faTimes} style={{ "color": "red" }} size='lg' onClick={(e) => { this.removeProduct.bind(this)(e, p) }} />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>);
    }
}

export default ProductOrder;