import { faPencilAlt, faSave, faSignInAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Card, Col, Form, InputGroup, Modal } from "react-bootstrap"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { getCodeTable, sendHttpRequest, storeDatabseEntity } from '../../utils/apiUtils'
import { localToUtcDateTime, utcToLocalDate } from '../../utils/dateUtils'
import useUser from '../hooks/useUser'
import ActionButton from "../layout/ActionButton"
import CardActivityLog from './CardActivityLog'

const ReplaceCard = ({ show, onShowChanged, customerPhone, identifier }) => {
    const history = useNavigate();
    const { user } = useUser();

    const [error, setError] = useState('') // eslint-disable-line no-unused-vars
    const [validated, setValidated] = useState(false);


    const onSaveCardReplace = async (e) => {
        const form = e.target.form || e.target;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            const response = await sendHttpRequest("/api/cards/replace/" + form.currentCardIdentifier.value, "PUT", form.newCardIdentifier.value, user)
            if (response?.status !== 200) {
                const error = await response.json()
                setError(error.message);
                toast.error(error.message, { autoClose: false, onClose: (e) => { setError('') } });
            } else {
                const result = await response.text(); // eslint-disable-line no-unused-vars
                toast.success('Данните са запазени', {
                    onOpen: (e) => { setError('') },
                    autoClose: 500,
                    onClose: () => {
                        history('/customers', { state: { searchKeyword: customerPhone } });
                    }
                });
            }
        }
    }
    const onCloseCardReplace = (e) => {
        e?.stopPropagation();
        onShowChanged(false);
        setValidated(false);
        setError('')
        toast.dismiss()
    }

    return (<>
        <Modal className="text-dark" show={show} onHide={onCloseCardReplace}>
            <Modal.Header closeButton>
                <Modal.Title>Замяна на карта</Modal.Title>
            </Modal.Header>
            <Form validated={validated} onSubmit={onSaveCardReplace}>
                <Modal.Body>
                    <Form.Group controlId="formGridFirstName">
                        <Form.Label>Текуща карта</Form.Label>
                        <Form.Control type="text" placeholder="Номер" name="currentCardIdentifier" value={identifier} disabled autoComplete="off" />
                    </Form.Group>
                    <Form.Group controlId="formGridАllowedTickets">
                        <Form.Label>Нова карта</Form.Label>
                        <Form.Control type="text" placeholder="Нова карта" name="newCardIdentifier" required autoComplete="off" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton variant="secondary" onClick={onCloseCardReplace}>
                        Close
                    </ActionButton>
                    <ActionButton variant="dpool" onClick={onSaveCardReplace}>
                        Save Changes
                    </ActionButton>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}


const EditCard = () => {
    const location = useLocation();
    const history = useNavigate();
    let cardState = location?.state?.card || {};
    let searchKeyword = location?.state?.searchKeyword || '';
    const [card, setCard] = useState({
        ...cardState,
        ticketsCountNew: (cardState?.ticketsCount || 0),
        ticketsCount: (cardState?.ticketsCount || 0),
        location: cardState?.location ? { ...cardState.location, value: cardState?.location?.id, label: cardState?.location?.name } : null,
        cardType: cardState?.type ? { value: cardState?.type?.id, label: cardState?.type?.name } : null,
        customerId: cardState?.customer?.id,
        activeFrom: (utcToLocalDate(cardState?.activeFrom) || new Date().toISOString()),
        activeTo: utcToLocalDate(cardState?.activeTo),
    })
    const [error, setError] = useState('') // eslint-disable-line no-unused-vars
    const { user } = useUser();
    const [defaultCardType, setDefaultCardType] = useState();
    const [show, setShow] = useState(false);


    useEffect(() => {
        getCodeTable("/api/config/cardTypes", user, (cardTypes) => {
            setDefaultCardType(cardTypes[0]);
        })
    }, [user])

    const getLocations = (inputValue, callback) => {
        getCodeTable("/api/config/locations", user, callback)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if (card.activeFromNew !== undefined) {
            card.activeFrom = card.activeFromNew
        }

        var from = new Date(card.activeFrom ? localToUtcDateTime(card.activeFrom.split("T")[0] + 'T00:00:00.000') : localToUtcDateTime('2000-01-01T00:00:00.000'));
        let to = new Date(card.activeTo ? localToUtcDateTime(card.activeTo.split("T")[0] + 'T23:59:59.999') : localToUtcDateTime('9999-12-31T23:59:59.999'));

        const reqBody = {
            id: card.id,
            identifier: card.identifier,
            location: card.location.value,
            cardType: defaultCardType.value,
            activeFrom: from.toISOString(),
            activeTo: to.toISOString(),
            ticketsCount: card.ticketsCountNew,
            customerId: card.customerId
        }

        await storeDatabseEntity(reqBody, 'cards', user,
            (e) => { // onError 
                setError(e);
                toast.error(e, { autoClose: false, onClose: (e) => { setError('') } });
            }, () => { // onSuccess
                toast.success('Карта ' + card.identifier + ' на ' + cardState?.customer?.name + ' е запазена', {
                    onOpen: (e) => { setError('') },
                    onClose: (e) => { history('/') },
                    autoClose: 2000
                });
            })
    }

    const getAllowedTickets = () => {
        let opts = []
        opts[parseInt(card.ticketsCount)] = { value: parseInt(card.ticketsCount), label: card.ticketsCount }
        card.location?.allowedTickets.split(',').forEach(t => {
            let optVal = parseInt(card.ticketsCount) + parseInt(t)
            let optLbl = card.ticketsCount + ' + ' + t

            opts[optVal] = { value: optVal, label: optLbl }
        })

        return opts;
    }

    const onLocationChange = (e) => {
        const toDt = new Date()
        toDt.setDate(toDt.getDate() + (e.forTimeCards !== false ? 30 : 90))

        setCard({ ...card, location: e, ticketsCountNew: card.ticketsCount, activeTo: utcToLocalDate(toDt), timeCard: e.forTimeCards })
    }

    const onTicketsChange = (e) => {
        let selectedValue = parseInt(e.value)
        console.log('selectedValue: ' + selectedValue + ' tickets count: ' + card.ticketsCount)
        if (card.ticketsCount !== selectedValue) {
            if (card.activeFromNew !== card.activeFrom) {
                setCard({ ...card, ticketsCountNew: selectedValue, activeFromNew: new Date().toISOString() })
            } else {
                setCard({ ...card, ticketsCountNew: selectedValue })
            }
        } else {
            if (card.activeFromNew !== card.activeFrom) {
                delete card.activeFromNew
                setCard({ ...card, ticketsCountNew: selectedValue })
            } else {
                setCard({ ...card, ticketsCountNew: selectedValue })
            }
        }
    }

    const onStartDateChange = (e) => {
        const fromDt = e.target.value
        const toDt = new Date(fromDt || 'T00:00:00.000')
        toDt.setDate(toDt.getDate() + (card.timeCard !== false ? 30 : 90))

        setCard({ ...card, activeFrom: fromDt, activeFromNew: fromDt, activeTo: utcToLocalDate(toDt) })
    }

    const styles = {
        option: (provided, state) => ({
            ...provided,
            color: "#495057",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#495057"
        })
    };

    return (<>
        {((cardState?.id && location?.pathname === '/customers/card/edit') || (cardState?.customer && location?.pathname === '/customers/card/add')) ?
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <FontAwesomeIcon icon={faSignInAlt} /> {(card?.id ? 'Редактиране' : 'Добавяне') + 'на карта за ' + cardState.customer.name}
                    <span style={{ float: "right" }}>
                        <ActionButton size="sm" variant="success" type="submit" onClick={onSubmit}><FontAwesomeIcon icon={faSave} /> Запази</ActionButton>
                        &nbsp;
                        <ActionButton size="sm" variant="danger" type="button" onClick={(e) => {
                            history('/customers', { state: { searchKeyword: searchKeyword } });
                        }}>Откажи <FontAwesomeIcon icon={faTimesCircle} /></ActionButton>
                    </span>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={onSubmit} id="id">
                        {card.id ? <>
                            <ReplaceCard show={show} onShowChanged={(e) => setShow(e)} identifier={card.identifier} customerPhone={card.customer.phone} />
                            <Form.Row>
                                <Form.Group controlId="formGridIdentifier" as={Col}>
                                    <Form.Label>Номер</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" name="location" value={card.identifier} disabled autoComplete="off" />
                                        <InputGroup.Append>
                                            <span className="dpool-action-link">
                                                <FontAwesomeIcon icon={faPencilAlt} onClick={(e) => setShow(true)} />
                                            </span>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formGridUsedOn" as={Col}>
                                    <Form.Label>Последно обновена на</Form.Label>
                                    <Form.Control type="text" name="createdBy" value={new Date(card.dateUpdated).toLocaleString('bg-BG')} disabled autoComplete="off" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridAddedBy" as={Col}>
                                    <Form.Label>Добавена от (на)</Form.Label>
                                    <Form.Control type="text" name="createdBy" value={card.createdBy.name + " (" + new Date(card.dateCreated).toLocaleString('bg-BG') + ")"}
                                        disabled autoComplete="off" />
                                </Form.Group>
                                <Form.Group controlId="formGridAddedOn" as={Col}>
                                    <Form.Label>Последно редактирана от (на)</Form.Label>
                                    <Form.Control type="text" name="createdBy" value={card.updatedBy.name + " (" + new Date(card.dateUpdated).toLocaleString('bg-BG') + ")"} disabled autoComplete="off" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridLocation" as={Col}>
                                    <Form.Label>Услуга</Form.Label>
                                    <AsyncSelect loadOptions={getLocations} defaultOptions onChange={onLocationChange} styles={styles}
                                        value={card.location} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridPhone" as={Col}>
                                    <Form.Label>Валидна от</Form.Label>
                                    <Form.Control type="date" placeholder="Валидна от" name="activeFrom" required autoComplete="off"
                                        value={card.activeFrom} onChange={onStartDateChange} />
                                </Form.Group>
                                <Form.Group controlId="formGridAddress" as={Col}>
                                    <Form.Label>Валидна до</Form.Label>
                                    <Form.Control type="date" placeholder="Валидна до" name="activeTo" autoComplete="off"
                                        value={card.activeTo} onChange={(e) => setCard({ ...card, activeTo: e.target.value })} />
                                </Form.Group>
                                {!card.timeCard &&
                                    <Form.Group controlId="formGridAllowedTickets" as={Col}>
                                        <Form.Label>Посещения</Form.Label>
                                        <Select options={getAllowedTickets()} onChange={onTicketsChange} styles={styles}
                                            value={getAllowedTickets()[card.ticketsCountNew]} isSearchable={false} />
                                    </Form.Group>
                                }
                            </Form.Row>
                            <CardActivityLog card={card} />
                        </> : <>
                            <Form.Row>
                                <Form.Group controlId="formGridIdentifier" as={Col}>
                                    <Form.Label>Номер</Form.Label>
                                    <Form.Control type="text" placeholder="Номер" name="location" required autoComplete="off"
                                        value={card.identifier} onChange={(e) => setCard({ ...card, identifier: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="formGridLocation" as={Col}>
                                    <Form.Label>Услуга</Form.Label>
                                    {!card.location &&
                                        <input className="requredSelect" required />
                                    }
                                    <AsyncSelect loadOptions={getLocations} defaultOptions styles={styles} placeholder="Услуга"
                                        value={card.location} onChange={onLocationChange} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="formGridPhone" as={Col}>
                                    <Form.Label>Валидна от</Form.Label>
                                    <Form.Control type="date" placeholder="Валидна от" name="activeFrom" required autoComplete="off"
                                        value={utcToLocalDate(card.activeFrom)} onChange={onStartDateChange} />
                                </Form.Group>
                                <Form.Group controlId="formGridAddress" as={Col}>
                                    <Form.Label>Валидна до</Form.Label>
                                    <Form.Control type="date" placeholder="Валидна до" name="activeTo" autoComplete="off"
                                        value={utcToLocalDate(card.activeTo)} onChange={(e) => setCard({ ...card, activeTo: e.target.value })} />
                                </Form.Group>
                                {!card.timeCard &&
                                    <Form.Group controlId="formGridAllowedTickets" as={Col}>
                                        <Form.Label>Посещения</Form.Label>
                                        <Select styles={styles} options={getAllowedTickets()} onChange={onTicketsChange}
                                            value={getAllowedTickets()[card.ticketsCountNew]} isSearchable={false} />
                                    </Form.Group>
                                }
                            </Form.Row>
                        </>}
                    </Form>
                </Card.Body>
            </Card>
            : <Navigate to={{ pathname: "/customers", state: { searchKeyword: searchKeyword } }} />}
    </>)
}

export default EditCard
