import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleKeyPress, sendHttpRequest } from '../../utils/apiUtils';
import CardList from '../cards/CardList';
import useUser from '../hooks/useUser';

function CustomerList() {

    const location = useLocation();
    const { user } = useUser();
    const [error, setError] = useState('')
    const [searchKeyword, _setSearchKeyword] = useState(location?.state?.searchKeyword || '')
    const searchKeywordRef = useRef(searchKeyword)
    const setSearchKeyword = x => {
        searchKeywordRef.current = x; // keep updated
        _setSearchKeyword(x);
    };

    const [jsonData, setJsonData] = useState([]);

    const showError = (msg) => {
        setError(msg);
        toast.error(
            msg,
            { autoClose: false, onClose: (e) => { setError('') } }
        );
    }

    const onSearch = useCallback(e => {

        let sk = e?.target ? e.target.value : e;
        setSearchKeyword(sk)

        if (sk.trim().length > 0 && sk.trim().length < 3) {
            setJsonData([]);
            return;
        }

        sendHttpRequest("/api/customers/search?q=" + sk, "GET", null, user)
            .then(response => {
                response.json().then(jsonData => {
                    if (response?.status === 403) {
                        if (!error) {
                            showError('Нямате достъп за желаната операция')
                        } else {
                            showError("Грешка: " + error)
                        }
                    } else {
                        setJsonData(jsonData);
                    }
                })
            })

    }, [error, user])

    useEffect(() => {
        const handleUserKeyPress = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                onSearch(searchKeywordRef.current);
            } else {
                let searchKeyword = searchKeywordRef.current
                searchKeyword = handleKeyPress(e, searchKeyword)
                setSearchKeyword(searchKeyword)
            }
        };

        window.addEventListener('keydown', handleUserKeyPress);
        onSearch(searchKeywordRef.current)
        return () => window.removeEventListener('keydown', handleUserKeyPress);
    }, [onSearch])



    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement">
            <Card.Header className="left" style={{ cursor: 'pointer' }}>
                <Form.Control id="searchCustomer" type="text" placeholder="Търсене на клиент ..." name="search"
                    onChange={(e) => { let sk = e?.target ? e.target.value : e; setSearchKeyword(sk); }} autoComplete="off" value={searchKeyword} />
            </Card.Header>
        </Card>
        <Accordion {...(jsonData?.length === 1 ? { activeKey: jsonData[0].id } : '')}>
            {jsonData?.map(customer => (
                <Card className="border border-dark bg-dark-dpool text-white centerElement" key={customer.id} style={{ margin: "10px 0px 10px 0px" }}>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey={customer.id} >
                        <Card.Header className="left" style={{ cursor: 'pointer' }}>
                            <h3>
                                <span>{customer.name}, {customer.phone}, {customer.emailAddress}, {customer.comment}
                                    <Link className="dpool-action-link" to={"/customers/edit"} state={{ cust: customer, searchKeyword: searchKeyword }} >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Link>
                                </span>
                                <span style={{ float: "right" }}>
                                    <Accordion.Collapse eventKey={customer.id}>
                                        <Link className="btn btn-dpool btn-sm" to={"/customers/card/add"} state={{ card: { customer: customer }, searchKeyword: searchKeyword }}>
                                            Добави Карта или Услуга
                                        </Link>
                                    </Accordion.Collapse>
                                </span>
                            </h3>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={customer.id} >
                        <Card.Body>
                            <CardList list={customer?.cards} customer={customer} searchKeyword={searchKeyword} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    </>
    )
}

export default CustomerList
