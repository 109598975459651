import { faSignInAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendHttpRequest } from '../utils/apiUtils';
import useUser from './hooks/useUser';

toast.configure();
const Login = () => {
    const [employee, setEmployee] = useState({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        roleId: ''
    })

    const { user, setUser } = useUser();
    const [error, setError] = useState('')
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const logout = params.get('logout');

        if (logout !== null) {
            setUser(null)
            window.location.href = '/';
        }

    }, [setUser, location.search])


    const onSubmit = async (e) => {
        e.preventDefault();

        const loginSpinner = document.getElementById("loginSpinner")

        if (loginSpinner.style.display === "inline") {
            return;
        }

        loginSpinner.style.display = "inline"

        const userJson = {
            username: employee.username,
            password: employee.password
        };

        const response = await sendHttpRequest("/api/employees/login", "POST", userJson, null) // eslint-disable-line no-unused-vars
            .then(async (response) => {
                const user = await response.json();
                if (user?.status) {
                    setError({ error });
                    toast.error("You have entered an invalid username or password");
                } else {
                    setUser(user);
                    window.location.href = "/";
                }
            }).catch(error => {
                setError({ error });
                console.log(error)
                toast.error("Request failed: " + error, { autoClose: false });
            })

        loginSpinner.style.display = "none"
    }

    return (<>
        {user?.username ? window.location.href = "/" :
            <Card style={{ maxWidth: "36rem" }} className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header><FontAwesomeIcon icon={faSignInAlt} /> Login</Card.Header>
                <Card.Body>
                    <Form onSubmit={onSubmit} id="id">
                        <Form.Group controlId="formGridUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Enter your username" name="username" value={employee.username} onChange={(e) => setEmployee({ ...employee, username: e.target.value })} required autoComplete="off" />
                        </Form.Group>
                        <Form.Group controlId="formGridPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter your password" name="password" value={employee.password} onChange={(e) => setEmployee({ ...employee, password: e.target.value })} required autoComplete="off" />
                        </Form.Group>
                        <Button size="sm" variant="success" type="submit" onClick={onSubmit}>
                            <FontAwesomeIcon icon={faSignInAlt} /> Login <FontAwesomeIcon id="loginSpinner" icon={faSpinner} pulse style={{ display: "none" }} />
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        }
    </>
    )
}

export default Login
