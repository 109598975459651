import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import CheckCard from './components/cards/CheckCard';
import EditCard from './components/cards/EditCard';
import ChangePassword from './components/config/ChangePassword';
import Settings from './components/config/Settings';
import CLastActions from './components/customers/CLastActions';
import Customer from './components/customers/Customer';
import CustomerList from './components/customers/CustomerList';
import Employee from './components/employees/Employee';
import useUser from './components/hooks/useUser';
import NavigationBar from './components/layout/NavigationBar';
import SideBar from './components/layout/SideBar';
import Login from "./components/Login";
import CashdeskReport from './components/reports/CashdeskReport';
import CheckedCardsByPeriod from './components/reports/CheckedCardsByPeriod';
import IssuedCardsByPeriod from './components/reports/IssuedCardsByPeriod';
import CashRegister from './components/warehouse/CashRegister';
import Product from './components/warehouse/Product';
import ProductList from './components/warehouse/ProductList';
import ProductOrder from './components/warehouse/ProductOrder';
import Welcome from './components/Welcome';
import './custom.scss';
import './dpool-bootstrap.scss';

const Logout = () => {
  const { user, setUser } = useUser();

  if (user === null || user === undefined) {
  } else {
    setUser(null)
  }

  setTimeout(() => {
    window.location.href = '/';
  }, 200);

  return (<></>)
}

function App() {

  const { user, isAuthorized } = useUser();

  const PrivateRoute = ({ children }) => {
    const authed = user?.username //
    return authed ? children : <Navigate to="/login" />;
  }

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} limit={2} autoClose={3000} position="bottom-center" style={{ width: 'inherit' }} />
      <SideBar />
      <NavigationBar />
      <Container>
        <Row>
          <Col lg={12} style={{ marginTop: "20px" }} >
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/checkcard" element={<PrivateRoute><CheckCard /></PrivateRoute>} />

              {isAuthorized("changePassword") && <Route path="/config/employees/changePassword" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />}

              {/* Customers */}
              {isAuthorized("customers") && <Route path="/customers" element={<PrivateRoute><CustomerList /></PrivateRoute>} />}
              {isAuthorized("customers/edit") && <Route path="/customers/edit" element={<PrivateRoute><Customer /></PrivateRoute>} />}
              {isAuthorized("customers/add") && <Route path="/customers/add" element={<PrivateRoute><Customer /></PrivateRoute>} />}

              {/* Cards */}
              {isAuthorized("customers/card/edit") && <Route path="/customers/card/edit" element={<PrivateRoute><EditCard /></PrivateRoute>} />}
              {isAuthorized("customers/card/add") && <Route path="/customers/card/add" element={<PrivateRoute><EditCard /></PrivateRoute>} />}
              {isAuthorized("customers/lastActions") && <Route path="/customers/lastActions" element={<PrivateRoute><CLastActions /></PrivateRoute>} />}

              {/* Configuration */}
              {isAuthorized("config") && <Route path="/config" element={<PrivateRoute><Settings /></PrivateRoute>} />}
              {isAuthorized("employees") && <Route path="/config/employees/add" element={<PrivateRoute><Employee /></PrivateRoute>} />}
              {isAuthorized("employees") && <Route path="/config/employees/edit" element={<PrivateRoute><Employee /></PrivateRoute>} />}

              {/* Reports */}
              {isAuthorized("reports/cashdesk") && <Route path="/reports/cashdesk" element={<PrivateRoute><CashdeskReport /></PrivateRoute>} />}

              {isAuthorized("reports/checkedCardsByPeriod") && <Route path="/reports" />}
              {isAuthorized("reports/checkedCardsByPeriod") && <Route path="/reports/checkedCardsByPeriod"
                element={<PrivateRoute><CheckedCardsByPeriod type="summary" /></PrivateRoute>} />}
              {isAuthorized("reports/checkedCardsByPeriod") && <Route path="/reports/checkedCardsByCustomerAndPeriod"
                element={<PrivateRoute><CheckedCardsByPeriod type="detailed" /></PrivateRoute>} />}
              {isAuthorized("reports/issuedCardsByPeriod") && <Route path="/reports/issuedCardsByPeriod"
                element={<PrivateRoute><IssuedCardsByPeriod type="detailed" /></PrivateRoute>} />}
              {isAuthorized("reports/issuedCardsByPeriod") && <Route path="/reports/issuedCardsByLocationAndPeriod"
                element={<PrivateRoute><IssuedCardsByPeriod type="summary" /></PrivateRoute>} />}

              {/* Warehouse */}
              {isAuthorized("warehouse/cashregister") && <Route path="/cashregister" element={<PrivateRoute><CashRegister /></PrivateRoute>} />}
              {isAuthorized("warehouse/products") && <Route path="/products" element={<PrivateRoute><ProductList /></PrivateRoute>} />}
              {isAuthorized("warehouse/products/add") && <Route path="/products/add" element={<PrivateRoute><Product /></PrivateRoute>} />}
              {isAuthorized("warehouse/products/edit") && <Route path="/products/edit" element={<PrivateRoute><Product /></PrivateRoute>} />}
              {isAuthorized("warehouse/products/delivery") && <Route path="/products/delivery" element={<PrivateRoute><ProductOrder orderType="delivery" /></PrivateRoute>} />}
              {isAuthorized("warehouse/products/writeOut") && <Route path="/products/writeOut" element={<PrivateRoute><ProductOrder orderType="writeOut" /></PrivateRoute>} />}
              {isAuthorized("warehouse/products/overhaul") && <Route path="/products/overhaul" element={<PrivateRoute><ProductOrder orderType="overhaul" /></PrivateRoute>} />}

              <Route path="*" element={<Navigate to="/" />} />

            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
