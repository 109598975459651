import { useCallback, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

const SearchProductField = ({ allValues, callback }) => {

    const [searchKeyword, _setSearchKeyword] = useState('')
    const searchKeywordRef = useRef(searchKeyword)

    const setSearchKeyword = x => {
        searchKeywordRef.current = x // keep updated
        _setSearchKeyword(x)
        onSearch(x)
    };

    const onSearch = useCallback((searchKeyword) => {

        if (allValues === undefined)
            return

        const searchWords = searchKeyword.split(' ')
        const result = allValues.filter(p => {

            var result = true
            searchWords.forEach(w => {
                const rx = new RegExp(w, "i")
                const match = rx.test(p.name) || rx.test(p.barcode)

                result = result && match
            });

            return result
        })

        callback(result)
    
    }, [callback, allValues])

    return (<>
    <Form.Control id="searchProduct" type="text" placeholder="Търсене ..." name="search" className=''
                        onChange={(e) => { let sk = e?.target ? e.target.value : e; setSearchKeyword(sk); }} autoComplete="off" value={searchKeyword} />
    </>)
}

export default SearchProductField