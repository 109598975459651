import { faCashRegister, faChartLine, faCog, faKey, faPowerOff, faSignInAlt, faTachometerAlt, faUserFriends, faWineBottle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAppVersion } from "../../utils/apiUtils";
import useUser from '../hooks/useUser';
import StyledSideNav, { Nav, NavIcon, NavInfoPane, NavItem, NavText, Separator, Toggle } from './StyledSideNav';

function SideBar() {

    const [selected, setSelected] = useState('');
    const [expanded, setExpanded] = useState(false);
    const { user, isAuthorized } = useUser();
    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        calculateSelected(location?.pathname)
    })

    const calculateSelected = (item) => {
        switch (item) {
            case "":
                setSelected("/");
                break;
            case "/customers/edit":
            case "/customers/card/add":
            case "/customers/card/edit":
                setSelected("/customers");
                break;
            case "/products/edit":
            case "/products/add":
            case "/products/delivery":
                setSelected("/products");
                break;
            default:
                setSelected(item);
        }
    }

    const onSelect = (item) => {
        calculateSelected(item)
        history(item, { state: { qResult: [] } })
        setExpanded(false)
    }

    const getFormatedDate = () => {
        return new Date().toLocaleString('bg-BG');
    }

    return (<>
        <StyledSideNav onSelect={onSelect} onToggle={setExpanded} expanded={expanded}>
            <Toggle />

            {expanded &&
                <NavInfoPane>
                    {user?.username && <>
                        {<div>{user.firstName} {user.lastName}</div>}
                        <div>{user.username} ( <Link to={"/config/employees/changePassword"} onClick={(e) => { onSelect("/config/employees/changePassword") }} >Промени парола</Link> <FontAwesomeIcon icon={faKey} />  )</div>
                        <div>{getFormatedDate()}</div>
                    </>}
                    <div>{getAppVersion()}</div>
                </NavInfoPane>
            }

            <Nav selected={selected}>
                {user?.username && isAuthorized("warehouse/cashregister") &&
                    <NavItem eventKey="/cashregister">
                        <NavIcon><FontAwesomeIcon icon={faCashRegister} size={"2x"} /></NavIcon>
                        <NavText>Каса</NavText>
                    </NavItem>
                }
                {user?.username &&
                    <NavItem eventKey="/checkcard">
                        <NavIcon><FontAwesomeIcon icon={faTachometerAlt} size={"2x"} /></NavIcon>
                        <NavText>Табло</NavText>
                    </NavItem>
                }
                {user?.username && isAuthorized("customers") &&
                    <NavItem onClick={(e) => onSelect('/customers')}>
                        <NavIcon><FontAwesomeIcon icon={faUserFriends} size={"2x"} /></NavIcon>
                        <NavText>Клиенти</NavText>
                        <NavItem eventKey="/customers">
                            <NavText>Търсене</NavText>
                        </NavItem>
                        <NavItem eventKey="/customers/add">
                            <NavText>Добавяне</NavText>
                        </NavItem>
                    </NavItem>
                }
                {user?.username && isAuthorized("warehouse/products/delivery") && !isAuthorized("warehouse/products") &&
                    < NavItem eventKey="/products/delivery">
                        <NavIcon><FontAwesomeIcon icon={faWineBottle} size={"2x"} /></NavIcon>
                        <NavText>Продукти</NavText>
                    </NavItem>
                }
                {user?.username && isAuthorized("warehouse/products") &&
                    <NavItem eventKey="/products">
                        <NavIcon><FontAwesomeIcon icon={faWineBottle} size={"2x"} /></NavIcon>
                        <NavText>Продукти</NavText>
                    </NavItem>
                }
                {isAuthorized("reports") &&
                    <NavItem onClick={(e) => onSelect('/reports')} eventKey="/reports" >
                        <NavIcon><FontAwesomeIcon icon={faChartLine} size={"2x"} /></NavIcon>
                        <NavText>Справки</NavText>
                        {isAuthorized("reports/cashdesk") &&
                            <NavItem eventKey="/reports/cashdesk"   >
                                <NavText style={{ borderBottom: '1px solid white' }}>Каса</NavText>
                            </NavItem>
                        }
                        <NavItem eventKey="/reports/checkedCardsByPeriod"   >
                            <NavText style={{ borderBottom: '1px solid white' }}>01. Маркирани карти по услуга за период</NavText>
                        </NavItem>
                        <NavItem eventKey="/reports/issuedCardsByLocationAndPeriod"   >
                            <NavText style={{ borderBottom: '1px solid white' }}>02. Издадени или подновени карти по услуга за период</NavText>
                        </NavItem>
                        <NavItem eventKey="/reports/checkedCardsByCustomerAndPeriod"   >
                            <NavText style={{ borderBottom: '1px solid white' }}>03. Маркирани карти по клиент и услуга за период</NavText>
                        </NavItem>
                        <NavItem eventKey="/reports/issuedCardsByPeriod"   >
                            <NavText style={{ borderBottom: '1px solid white' }}>04. Издадени или подновени карти за период</NavText>
                        </NavItem>
                    </NavItem>
                }
                {isAuthorized("config") &&
                    <NavItem eventKey="/config">
                        <NavIcon><FontAwesomeIcon icon={faCog} size={"2x"} /></NavIcon>
                        <NavText>Служители</NavText>
                    </NavItem>
                }
                <Separator />
                {user?.username ?
                    <NavItem eventKey={'/logout'} >
                        <NavIcon style={{ opacity: 1, color: 'red' }} ><FontAwesomeIcon icon={faPowerOff} className="text-danger" /></NavIcon>
                        <NavText style={{ opacity: 1, color: 'red' }} title={'Изход'}>{'Изход'}</NavText>
                    </NavItem>
                    :
                    <NavItem eventKey={'/login'} >
                        <NavIcon><FontAwesomeIcon icon={faSignInAlt} style={{ opacity: 1, color: '#fff', fontWeight: '700' }} /></NavIcon>
                        <NavText style={{ opacity: 1, fontWeight: '700' }} title={'Вход'}>{'Вход'}</NavText>
                    </NavItem>
                }
            </Nav>
        </StyledSideNav>
    </>)
}

export default SideBar
