import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { handleKeyPress } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';

const ProductListSimple = ({ onClickHandler, products }) => {

    const location = useLocation();
    const [searchKeyword, _setSearchKeyword] = useState(location?.state?.searchKeyword || '')

    const searchKeywordRef = useRef(searchKeyword)
    const [prdState, setPrdState] = useState([])

    const { user } = useUser();

    const setSearchKeyword = x => {
        searchKeywordRef.current = x // keep updated
        _setSearchKeyword(x)
        onSearch(x, false, false)
    };

    const clickHandler = useCallback(p => {
        setSearchKeyword('')
        onClickHandler(p)
    }, [onClickHandler])

    const onSearch = useCallback((searchKeyword, showAll = false, simpleAdd = false) => {

        // Override the passed value and always show all products
        showAll = true

        if (products === undefined || !Array.isArray(products) || products.length === 0) {
            return
        }

        // const regex = new RegExp(searchKeyword, "i");

        const searchWords = searchKeyword.split(' ')
        const result = products.filter(el => {
            if (el.barcode === searchKeyword) {
                return true
            }

            var result = true
            searchWords.forEach(w => {
                const rx = new RegExp(w, "i")
                const match = rx.test(el.name) || rx.test(el.barcode)

                result = result && match
            });

            return result && (showAll || el.quantity !== 0)
            //return ((el.barcode === searchKeyword || regex.test(el.name) || regex.test(el.barcode)) && (showAll || el.quantity !== 0))
        })

        if (result.length === 1 && simpleAdd) {
            clickHandler(result[0])
        } else {
            setPrdState(result)
        }

    }, [clickHandler, products])



    useEffect(() => {
        const handleUserKeyPress = (e) => {
            if (document?.activeElement?.tagName === "BODY") {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSearch(searchKeywordRef.current, true, true);
                } else {
                    let searchKeyword = searchKeywordRef.current
                    searchKeyword = handleKeyPress(e, searchKeyword)
                    setSearchKeyword(searchKeyword)
                    onSearch(searchKeyword, false, false);
                }
            } else if (document?.activeElement?.name === 'search') {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSearch(searchKeywordRef.current, true, true);
                }
            } else {

            }
        };

        onSearch("", false, false);

        window.addEventListener('keydown', handleUserKeyPress);
        return () => window.removeEventListener('keydown', handleUserKeyPress);
    }, [user, onSearch, products]);


    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
            <Card.Header className="left" style={{ cursor: 'pointer' }}>
                <Form.Row><Col sm="12">
                    <Form.Control id="searchProduct" type="text" placeholder="Търсене ..." name="search" className=''
                        onChange={(e) => { let sk = e?.target ? e.target.value : e; setSearchKeyword(sk); }} autoComplete="off" value={searchKeyword} />
                </Col></Form.Row>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th>Цена</th>
                            <th>Кол</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prdState?.map((p) => (
                            <tr key={p.id} onClick={(e) => { clickHandler(p) }}>
                                <td>{p.name}</td>
                                <td>{p.priceSell?.toFixed(2)}</td>
                                <td style={(p.quantity < 1) ? { color: 'red' } : {}}>
                                    {p.productType.countable && p.quantity}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>

        </Card>
    </>)
}

export default ProductListSimple