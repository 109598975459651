import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

/* eslint-disable no-extend-native, no-unused-vars */

const pad = (n) => { return n < 10 ? '0' + n : n }

Date.prototype.toLocalISOString = function () {
  // ISO 8601
  var d = this
    , tz = d.getTimezoneOffset() //mins
    , tzs = (tz > 0 ? "-" : "+") + pad(parseInt(tz / 60))

  if (tz % 60 !== 0)
    tzs += pad(tz % 60)

  if (tz === 0) // Zulu time == UTC
    tzs = 'Z'

  var strDate = d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate())
  var strTime = pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds())

  var result = strDate + 'T' + strTime;

  return result;
}

Date.prototype.toLocalString = function () {
  // ISO 8601
  var d = this
  var strDate = d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate())
  var strTime = pad(d.getHours()) + ':' + pad(d.getMinutes())

  var result = strDate + ' ' + strTime;

  return result
}

Date.prototype.toLocalDate = function () {
  // ISO 8601
  var d = this
  var strDate = d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate())

  return strDate;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
