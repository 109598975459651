import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getCodeTable, storeDatabseEntity } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';

function CodeTable({ codeTableName, headerText, dataUrl }) {

    const [codeTableData, setCodeTable] = useState([]);
    const [show, setShow] = useState(false);
    const [editedItem, setEditedItem] = useState(undefined);
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('') // eslint-disable-line no-unused-vars
    const { user } = useUser();

    useEffect(() => {
        getCodeTable(dataUrl || "/api/config/" + codeTableName + "?all=true", user, setCodeTable)
    }, [codeTableName, dataUrl, user])

    const onAdd = (e) => {
        e.stopPropagation();
        setEditedItem({ active: true })
        setShow(true);
    }

    const onEdit = (e, item) => {
        e.stopPropagation();
        setEditedItem(item);
        setShow(true);
    }

    const onSave = async (e) => {
        const form = e.target.form || e.target;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            await storeDatabseEntity({ ...editedItem }, 'config/' + codeTableName, user,
                (e) => { // onError 
                    setError(e);
                    toast.error(e, { autoClose: false, onClose: (e) => { setError('') } });
                },
                () => { // onSuccess
                    toast.success('Данните са запазени', {
                        onOpen: (e) => { setError('') },
                        autoClose: 500,
                        onClose: () => { window.location.href = "/config" }
                    });
                })

            setEditedItem(undefined);
            setShow(false);
        }
    }
    const onClose = (e) => {
        e?.stopPropagation();
        setEditedItem(undefined);
        setShow(false);
        setValidated(false);
    }


    return (<>
        <Modal className="text-dark" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(editedItem === undefined || editedItem.id === undefined) ? ("Добавяне към " + headerText) : "Редактиране на '" + editedItem.label + "'"}
                </Modal.Title>
            </Modal.Header>
            <Form validated={validated} onSubmit={onSave}>
                <Modal.Body>
                    <Form.Group controlId="formGridFirstName">
                        <Form.Label>Наименование</Form.Label>
                        <Form.Control type="text" placeholder="Име" name="editedItemName" value={editedItem?.name}
                            onChange={(e) => { setEditedItem({ ...editedItem, name: e.target.value }); setValidated(false); }} required autoComplete="off" />
                    </Form.Group>
                    {editedItem?.code !== undefined || codeTableName === 'productCategories' ?
                        <Form.Group controlId="formGridCode">
                            <Form.Label>Код</Form.Label>
                            <Form.Control type="text" placeholder="Разрешени зареждания" name="editedItemАllowedTickets" value={editedItem?.code}
                                onChange={(e) => { setEditedItem({ ...editedItem, code: e.target.value }); setValidated(false); }} required autoComplete="off" />
                        </Form.Group>
                        : <></>}
                    {editedItem?.allowedTickets !== undefined || codeTableName === 'locations' ?
                        <Form.Group controlId="formGridАllowedTickets">
                            <Form.Label>Разрешени зареждания</Form.Label>
                            <Form.Control type="text" placeholder="Разрешени зареждания" name="editedItemАllowedTickets" value={editedItem?.allowedTickets}
                                onChange={(e) => { setEditedItem({ ...editedItem, allowedTickets: e.target.value }); setValidated(false); }} required autoComplete="off" />
                        </Form.Group>
                        : <></>}
                    {editedItem?.allowedTickets !== undefined || codeTableName === 'locations' ?
                        <Form.Group controlId="formGridАllowedTickets">
                            <Form.Label>Разрешени маркирания за ден</Form.Label>
                            <Form.Control type="text" placeholder="Разрешени маркирания за ден" name="editedItemАllowedChecksPerDay" value={editedItem?.allowedChecksPerDays}
                                onChange={(e) => { setEditedItem({ ...editedItem, allowedChecksPerDays: e.target.value }); setValidated(false); }} required autoComplete="off" />
                        </Form.Group>
                        : <></>}
                    {editedItem?.allowedNegativeTickets !== undefined || codeTableName === 'locations' ?
                        <Form.Group controlId="formGridАllowedNegativeTickets">
                            <Form.Check type="checkbox" name="editedItemАllowedNegativeTickets" label="Разрешени отрицателни маркирания" checked={editedItem?.allowedNegativeTickets}
                                onChange={(e) => { setEditedItem({ ...editedItem, allowedNegativeTickets: e.target.checked }); setValidated(false); }} autoComplete="off" />
                        </Form.Group>
                        : <></>}
                    <Form.Group controlId="formGridFirstName">
                        <Form.Check type="checkbox" name="editedItemActive" checked={editedItem?.active} label="Активна"
                            onChange={(e) => { setEditedItem({ ...editedItem, active: e.target.checked }); setValidated(false); }} autoComplete="off" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <ActionButton variant="dpool" onClick={onSave.bind(this)}>
                        Save Changes
                    </ActionButton>
                </Modal.Footer>
            </Form>
        </Modal>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key={codeTableName} style={{ margin: "10px 0px 10px 0px" }}>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey={codeTableName}>
                <Card.Header className="left" style={{ cursor: 'pointer' }}>
                    <h3>
                        <span>{headerText}</span>
                        <span style={{ float: "right" }}>
                            <Accordion.Collapse eventKey={codeTableName}>
                                <Button className="btn btn-dpool btn-sm" onClick={onAdd} >Добави</Button>
                            </Accordion.Collapse>
                        </span>
                    </h3>
                </Card.Header>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={codeTableName}>
                <Card.Body>
                    <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                        <colgroup>
                            <col span={codeTableName === 'locations' ? 5 : 3} />
                            <col span={1} style={{ width: "5%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Наименование</th>
                                {codeTableName === 'locations' ? <th>Възможни зареждания</th> : ''}
                                {codeTableName === 'locations' ? <th>Възможни маркирания</th> : ''}
                                {codeTableName === 'locations' ? <th>Отрицателни маркирания</th> : ''}
                                {codeTableName === 'productCategories' ? <th>Код</th> : ''}
                                <th>Активна</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {codeTableData.map((data) => (
                                <tr key={data.id}>
                                    <td>{data.id}</td>
                                    <td className="m-auto">{data.name}</td>
                                    {codeTableName === 'locations' ? <td className="m-auto"> {data.allowedTickets} </td> : ''}
                                    {codeTableName === 'locations' ? <td className="m-auto"> {data.allowedChecksPerDays} </td> : ''}
                                    {codeTableName === 'locations' ? <td className="m-auto"> {
                                        data.allowedNegativeTickets ?
                                            <span style={{ color: 'lime' }}>Да</span>
                                            :
                                            <span style={{ color: 'red' }}>Не</span>
                                    } </td> : ''}
                                    {codeTableName === 'productCategories' ? <td className="m-auto"> {data.code} </td> : ''}
                                    <td>{data.active ?
                                        <span style={{ color: 'lime' }}>Да</span>
                                        :
                                        <span style={{ color: 'red' }}>Не</span>}
                                    </td>
                                    <td>
                                        <span className="dpool-action-link"><FontAwesomeIcon icon={faPencilAlt} onClick={(e) => onEdit(e, data)} /></span>
                                        {/* <span className="dpool-action-link-delete"><FontAwesomeIcon icon={faTrashAlt} /></span> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    </>)
}

export default CodeTable
