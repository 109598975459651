import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { getData } from '../../utils/apiUtils';
import { utcToLocalDate } from '../../utils/dateUtils';
import useUser from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';


function CheckedCardsByPeriod({ type }) {

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const { user } = useUser();
    const [qParam, setQParams] = useState({ from: utcToLocalDate(firstDay), to: utcToLocalDate(lastDay) });
    const [qResult, setQResult] = useState(undefined);

    const [rDetailed, setRDetailed] = useState(undefined); // eslint-disable-line no-unused-vars
    const [rSummary, setRSummary] = useState(undefined);   // eslint-disable-line no-unused-vars

    const getSummaryData = (data) => {
        const toShow = new Map();
        data.forEach((currentValue) => {
            if (toShow.get(currentValue.location) === undefined) {
                toShow.set(currentValue.location, 0);
            }

            toShow.set(currentValue.location, toShow.get(currentValue.location) + parseInt(currentValue.check_COUNT, 10));
        });

        var result = [];
        toShow.forEach((v, k) => {
            result.push({ name: '', location: k, check_COUNT: v });
        });

        result.sort((e1, e2) => { return parseInt(e1.check_COUNT, 10) <= parseInt(e2.check_COUNT, 10) })

        return result;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setQResult(undefined);

        await getData('/api/reports/checkedCardsByPeriod?from=' + qParam.from + 'T00:00:00.000000Z&to=' + qParam.to + 'T23:59:59.999999Z', user, data => {

            if (data?.status) {
                setQResult([{ location: 'Възникна греша при работа с базата', name: '', check_COUNT: '' }]);
            } else if (data?.length) {
                const summaryData = getSummaryData(data)
                const detailedData = data;

                setRDetailed(detailedData)
                setRSummary(summaryData)

                switch (type) {
                    case 'summary':
                        setQResult(summaryData);
                        break;
                    case 'detailed':
                        setQResult(detailedData);
                        break;
                    default:
                }
            } else {
                setQResult([{ location: 'Няма резултати', name: '', check_COUNT: '' }]);
            }
        })
    }

    const getTitle = (tp) => {
        switch (tp) {
            case 'summary':
                return 'Маркирани карти по услуга за период';
            case 'detailed':
                return 'Маркирани карти по клиент и услуга за период';
            default:
                return 'Sprawka'
        }
    }

    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement">
            <Card.Header>
                <h3 style={{ textAlign: 'center' }}>{getTitle(type)}</h3>
                <Form onSubmit={onSubmit} id="id">
                    <Form.Row>
                        <Form.Group controlId="formGridPhone" as={Col}>
                            <Form.Label>От дата</Form.Label>
                            <Form.Control type="date" placeholder="Валидна от" name="activeFrom" value={qParam.from && new Date(qParam.from).toISOString().split("T")[0]}
                                onChange={(e) => setQParams({ ...qParam, from: e.target.value })}
                                required autoComplete="off" />
                        </Form.Group>
                        <Form.Group controlId="formGridAddress" as={Col}>
                            <Form.Label>До дата</Form.Label>
                            <Form.Control type="date" placeholder="Валидна до" name="activeTo" value={qParam.to && new Date(qParam.to).toISOString().split("T")[0]}
                                onChange={(e) => setQParams({ ...qParam, to: e.target.value })} required autoComplete="off" />
                        </Form.Group>
                        <Form.Group controlId="formGridRun" as={Col}>
                            <Form.Label>&nbsp;</Form.Label>
                            <ActionButton size="sm" variant="success" type="button" name="submit" className="form-control" onClick={onSubmit} >
                                Изпълни</ActionButton>
                        </Form.Group>

                        <Form.Group controlId="formGridExport" as={Col}>
                            <Form.Label>&nbsp;</Form.Label>
                            {qResult &&
                                <CSVLink className="btn btn-info btn-sm form-control" data={qResult}
                                    filename={"checkedCards_" + qParam.from + "_" + qParam.to + ".csv"}><FontAwesomeIcon icon={faFileExport} /></CSVLink>
                            }
                            {qResult === undefined &&
                                <Button size="sm" variant="info" type="button" name="export" className="form-control" disabled={true}>
                                    <FontAwesomeIcon icon={faFileExport} />
                                </Button>
                            }
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Header>
            {qResult && <Card.Body>
                <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                    <                   colgroup>
                        {type === "detailed" ? <col span={1} /> : <></>}
                        <col span={1} />
                        <col span={1} style={{ width: "33%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            {type === "detailed" ? <th>Клиент</th> : <></>}
                            <th>Услуга</th>
                            <th>Брой</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qResult.map((data) => (
                            <tr key={data.name + "," + data.location + "," + data.check_COUNT}>
                                {type === "detailed" ? <td>{data.name}</td> : <></>}
                                <td className="m-auto">{data.location}</td>
                                <td>{data.check_COUNT}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>}
        </Card>
    </>)
}

export default CheckedCardsByPeriod
