import { Accordion } from 'react-bootstrap';
import EmployeeList from '../employees/EmployeeList';
import CodeTable from './CodeTable';

const Settings = () => {

    return (<>
        <Accordion>
            <EmployeeList />
            <CodeTable codeTableName='cardTypes' headerText="Видове карти" />
            <CodeTable codeTableName='locations' headerText="Услуги" />
            <CodeTable codeTableName='productCategories' headerText="Категории продукти" />
            <CodeTable codeTableName='productTypes' headerText="Видове продукти" />
        </Accordion>
    </>)
}

export default Settings
