import { faSave, faSignInAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { getCodeTable, storeDatabseEntity } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';

const Employee = () => {

    const location = useLocation();
    const history = useNavigate();

    let employeeState = location?.state?.employee || {};

    const [employee, setEmployee] = useState({ ...employeeState, password: null, role: { value: employeeState?.role?.id, label: employeeState?.role?.name } })
    const { user } = useUser();
    const [error, setError] = useState('') // eslint-disable-line no-unused-vars

    const onSubmit = async (e) => {
        e.preventDefault()
        await storeDatabseEntity({ ...employee, roleId: employee.role.value }, 'employees', user,
            (e) => { // onError 
                setError({ e }); toast.error(e);
            },
            () => { // onSuccess
                toast.success('Служителят е запазен', {
                    onOpen: (e) => { setError('') },
                    autoClose: 500,
                    onClose: () => { window.location.href = "/config" }
                });
            })
    }

    const getRoles = (inputValue, callback) => {
        getCodeTable("/api/config/roles", user, callback)
    }

    const styles = {
        option: (provided, state) => ({
            ...provided,
            color: "#495057",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#495057"
        })
    };

    return (<>
        {((employee?.id !== undefined && location?.pathname === '/config/employees/edit') || (location?.pathname === '/config/employees/add')) ?
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <FontAwesomeIcon icon={faSignInAlt} /> {(employee?.id !== undefined ? 'Редактиране' : 'Добавяне') + 'на служител '}
                    <span style={{ float: "right" }}>
                        <ActionButton size="sm" variant="success" type="submit" onClick={onSubmit.bind(this)}><FontAwesomeIcon icon={faSave} /> Запази</ActionButton>
                        &nbsp;
                        <ActionButton size="sm" variant="danger" type="button" onClick={(e) => { history("/config") }}>Откажи <FontAwesomeIcon icon={faTimesCircle} /></ActionButton>
                    </span>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={onSubmit} id="id">
                        <Form.Row>
                            <Form.Group controlId="formGridFirstName" as={Col}>
                                <Form.Label>Име</Form.Label>
                                <Form.Control type="text" placeholder="Име" name="firstName" value={employee.firstName} required autoComplete="off"
                                    onChange={(e) => setEmployee({ ...employee, firstName: e.target.value })} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group controlId="formGridUsername" as={Col}>
                                <Form.Label>Потребител</Form.Label>
                                <Form.Control type="text" placeholder="Потребител" name="lastName" value={employee.username} required autoComplete="off"
                                    disabled={employee?.id !== undefined ? true : false} onChange={(e) => setEmployee({ ...employee, username: e.target.value })} />
                            </Form.Group>
                            <Form.Group controlId="formGridRole" as={Col}>
                                <Form.Label>Роля</Form.Label>
                                <AsyncSelect loadOptions={getRoles} defaultOptions value={employee.role}
                                    onChange={(e) => setEmployee({ ...employee, role: e })} styles={styles} />
                            </Form.Group>
                            <Form.Group controlId="formGridPassword" as={Col}>
                                <Form.Label>Парола</Form.Label>
                                <Form.Control type="password" placeholder="Парола" name="password" value={employee.password || ''} required autoComplete="off"
                                    onChange={(e) => setEmployee({ ...employee, password: (e.target.value !== '' ? e.target.value : '') })} />
                            </Form.Group>
                            <Form.Group controlId="formGridActive" as={Col} style={{ textAlign: "center" }}>
                                <Form.Label>Активен</Form.Label>
                                <Form.Check type="switch" checked={employee.active === undefined ? true : employee.active}
                                    onChange={(e) => setEmployee({ ...employee, active: e.target.checked })} styles={{ styles }} />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
            : <Navigate to={{ pathname: "/config" }} />
        }

    </>)
}

export default Employee
