import { faCreditCard, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { getCodeTable, handleKeyPress, sendHttpRequest } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';
import LoadingOverlay from "../layout/LoadingOverlay";

function CheckCard() {

    const { user, isAuthorized } = useUser()
    const [error, setError] = useState('')
    const [jsonData, setJsonData] = useState([])
    const [blink, setBlink] = useState('')
    const [showOverlay, setShowOverlay] = useState(false)

    const history = useNavigate()

    const [locationValue, _setLocationValue] = useState('')
    const [cardIdentifier, _setCardIdentifier] = useState('')

    const [checkedCardIdentifier, setCheckedCardIdentifier] = useState('')
    const [checkedLocationValue, setCheckedLocationValue] = useState('')

    const cardIdentifierRef = useRef(cardIdentifier)
    const locationValueRef = useRef(locationValue)

    const setCardIdentifier = x => {
        cardIdentifierRef.current = x // keep updated
        _setCardIdentifier(x)
    };

    const setLocationValue = x => {
        locationValueRef.current = x // keep updated
        _setLocationValue(x)
    };

    const showError = (msg) => {
        setError(msg)
        toast.error(msg, { autoClose: false, onClose: (e) => { setError('') } })
    }

    const showInfo = (msg) => {
        toast.success(msg, { onOpen: (e) => { setError(''); setBlink('blink') } })
    }

    const fetchData = useCallback(e => {

        var from = new Date()
        from.setHours(0)
        from.setMinutes(0)
        from.setSeconds(0)

        sendHttpRequest("/api/cards/activities?from=" + from.toLocalISOString(), "GET", null, user)
            .then(response => {
                response.json().then(jsonData => {
                    if (response?.status !== 200) {
                        if (!error) {
                            showError("Възникна грешка при извличане на последните маркирани карти")
                        } else {
                            showError("Error: " + error)
                        }
                    } else {
                        setJsonData(jsonData);
                    }
                })
            })

    }, [error, user]);

    const getLocations = useCallback((inputValue, callback) => {
        getCodeTable("/api/config/locations", user, (opt) => {
            if (opt?.length === 1) {
                setLocationValue(opt[0])
            } else {
                setLocationValue('')
            }
            callback(opt);
        })
    }, [user])

    const onSubmit = useCallback(e => {
        e?.preventDefault()
        e?.stopPropagation()

        if (showOverlay === true) {
            // prevent double submission
            return
        }


        setShowOverlay(true)

        let hasErrors = false

        if (!hasErrors && error !== '') {
            hasErrors = true
        }

        if (!hasErrors && locationValueRef.current === '') {
            showError("Моля изберете услуга")
            hasErrors = true
        }

        if (!hasErrors && cardIdentifierRef.current === '') {
            showError("Моля попълнете номер на карта");
            hasErrors = true
        }

        if (!hasErrors) {
            let cardId = cardIdentifierRef.current + ":" + locationValueRef.current.id
            setCheckedCardIdentifier(cardIdentifierRef.current)
            setCheckedLocationValue(locationValueRef.current.id)
            e?.target.reset();

            getLocations('', (e) => { });

            sendHttpRequest("/api/cards/check/" + cardId, "PUT", null, user)
                .then(response => {
                    response.json().then(jsonData => {

                        switch (jsonData?.status) {
                            case 402:
                            case 403:
                            case 404:
                            case 406:
                            case 417:
                                if (!error) {
                                    var errorMsg = (jsonData?.message || "Грешка при обработката '" + jsonData + "'")
                                    showError(errorMsg)
                                } else {
                                    showError("Error: " + error)
                                }

                                setCheckedCardIdentifier('')
                                setCheckedLocationValue('')
                                break;
                            default:
                                if (jsonData?.id) {
                                    showInfo("Карта с номер " + cardId + " бе маркирана успешно");
                                    fetchData();
                                }
                                break;
                        }

                        setShowOverlay(false);
                    })
                })
        }

        setCardIdentifier('')
        setLocationValue('')

    }, [error, fetchData, getLocations, showOverlay, user])



    const uncheckCard = async (e, activityLogId) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (showOverlay === true) {
            // prevent double submission
            return
        }

        try {
            setShowOverlay(true);
            const response = await sendHttpRequest("/api/cards/check/" + activityLogId, "DELETE", null, user);
            const jsonData = await response.json(); // eslint-disable-line no-unused-vars
            if (response?.status !== 200) {
                if (!error) {
                    showError("Възникна грешка при отмяната на действието")
                } else {
                    showError("Error: " + error)
                }
            } else {
                toast.success("Действието бе успешно отменено");
                fetchData();
            }
        } finally {
            setShowOverlay(false);
        }
    }



    const handleUserKeyPress = useCallback(e => { // eslint-disable-line no-unused-vars

        switch (e.key) {
            case 'Control': case 'Shift': case 'Alt': case 'AltGraph':
            case 'ArrowRight': case 'ArrowLeft': case 'ArrowUp': case 'ArrowDown':
            case 'F1': case 'F2': case 'F3': case 'F4': case 'F5': case 'F6':
            case 'F7': case 'F8': case 'F9': case 'F10': case 'F11': case 'F12':
                break;
            case 'Backspace':
                e?.preventDefault();
                window.data = window.data?.slice(0, -1)
                setCardIdentifier(window.data)
                setError('')
                toast.dismiss()
                break
            case 'Enter':
                e?.preventDefault();
                setCardIdentifier(window.data)
                window.data = ''
                onSubmit(null)

                break;
            default:
                e?.preventDefault();
                window.data += (e.key || String.fromCharCode(e.key))
                setCardIdentifier(window.data)
                setError('');
                toast.dismiss()
                break;
        }
    }, [onSubmit]);

    useEffect(() => {
        setShowOverlay(true);
        const handleUserKeyPress = (e) => {
            let cardIdentifier = cardIdentifierRef.current;
            cardIdentifier = handleKeyPress(e, cardIdentifier);
            setCardIdentifier(cardIdentifier);

            switch (e.key) {
                case 'Enter':
                    e?.preventDefault();
                    onSubmit(null)
                    break;
                default:
                    setError('')
                    toast.dismiss()
            }

        };

        window.addEventListener('keydown', handleUserKeyPress);
        fetchData();

        setShowOverlay(false);
        return () => window.removeEventListener('keydown', handleUserKeyPress);
    }, [onSubmit, fetchData])

    const openClinet = (e) => {
        history('/customers', { state: { searchKeyword: e?.customer?.phone } });
    }

    const Separator = () => (
        <span>, </span>
    );

    return (<>
        <LoadingOverlay show={showOverlay} />
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key="checkCard">
            <Card.Header className="left">
                <Form onSubmit={onSubmit} id="checkCard" style={{ color: '#000' }}>
                    <Row>
                        <Col sm={5}><AsyncSelect name="location" loadOptions={getLocations} defaultOptions value={locationValue}
                            onChange={(e) => { setLocationValue(e); setError(''); toast.dismiss() }} isSearchable={false} placeholder="Услуга" /></Col>
                        <Col><Form.Control type="text" id="cardIdentifier" placeholder="Номер на карта" name="cardIdentifier" autoComplete="off"
                            onChange={(e) => { setCardIdentifier(e.value); setError(''); toast.dismiss() }} value={cardIdentifier} /></Col>
                        <Col sm="auto"><Button size="sm" variant="dpool" type="submit"><FontAwesomeIcon icon={faCreditCard} /></Button></Col>
                    </Row>
                </Form>
            </Card.Header>
        </Card>
        {jsonData?.map((cal, i) => (
            <Card key={cal.id} className="border border-dark bg-dark-dpool text-white centerElement" style={{ margin: "10px 0px 10px 0px" }}>
                <Card.Header className="left" style={{ "cursor": "pointer" }} onClick={(e) => { openClinet(cal) }}>
                    <h5 className={(cal?.card?.identifier === checkedCardIdentifier && cal?.card?.location?.id === checkedLocationValue) ? blink : ''}>
                        <span>{cal.frontSortId}: </span>
                        {isAuthorized("card/uncheck") ?
                            <ActionButton size="sm" variant="danger" onClick={(e) => uncheckCard(e, cal.id)}><FontAwesomeIcon icon={faUndoAlt} /></ActionButton>
                            :
                            ''
                        }
                        <span>{new Date(cal?.dateCreated).toLocalString()}</span><Separator />
                        <span>{cal?.card?.identifier}</span><Separator />
                        <span>{cal?.customer?.name}</span><Separator />
                        <span style={{ color: '#eadb06' }} >{cal?.card?.location?.name}</span><Separator />
                        <span style={
                            (!isNaN(Date.parse(cal?.message?.split(' ')[2])) && Date.parse(cal?.message?.split(' ')[2]) <= new Date().setDate(new Date().getDate() + 2)) ?
                                { color: 'red' }
                                : (cal?.message?.split(' ')[3] <= 0) ?
                                    { color: 'red' }
                                    :
                                    (cal?.message?.split(' ')[3] <= 3) ?
                                        { color: 'orange' }
                                        :
                                        { color: 'lime' }
                        }>{cal?.message}</span>
                    </h5>
                </Card.Header>
            </Card>
        ))}
    </>)
}

export default CheckCard
