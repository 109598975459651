import { faClipboardCheck, faFileExport, faMinusSquare, faPencilAlt, faPlusSquare, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendHttpRequest } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';
import SearchProductField from '../layout/SearchProductField';

const ProductList = () => {

    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    const { user, isAuthorized } = useUser();
    const [error, setError] = useState('')

    const showError = (msg) => {
        setError(msg);
        toast.error(
            msg,
            { autoClose: false, onClose: (e) => { setError('') } }
        );
    }

    useEffect(() => {
        sendHttpRequest("/api/wrh/products/all", "GET", null, user)
            .then(response => {
                response.json().then(productsResponse => {
                    if (response?.status === 403) {
                        if (!error) {
                            showError('Нямате достъп за желаната операция')
                        } else {
                            showError("Грешка: " + error)
                        }
                    } else {
                        setProducts(productsResponse);
                        setAllProducts(productsResponse);
                    }
                })
            })

    }, [error, user]);

    const setGetExportData = (products) => {
        var result = [];

        products.forEach(p => {
            result.push({
                id: p.id,
                name: p.name,
                barcode: p.barcode,
                active: p.active,
                description: p.description,
                type: p.productType.name,
                priceDelivery: p.priceDelivery,
                priceSell: p.priceSell,
                category: p.category.name,
                quantity: p.quantity
            })
        })


        return result;
    };

    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
            <Card.Header className="left" style={{ cursor: 'pointer' }}>
                <Form.Row style={{ margin: "5px 0px -5px -5px" }}>
                    {isAuthorized('warehouse/products/delivery') &&
                        <Col style={{marginTop: "5px" }} sm="2"><Link className="btn btn-primary" to={"/products/delivery"} style={{ width: "100%" }}
                        ><FontAwesomeIcon icon={faTruck} /> Доставка</Link></Col>
                    }
                    {isAuthorized('warehouse/products/writeOut') &&
                        <Col style={{marginTop: "5px" }} sm="2"><Link className="btn btn-warning" to={"/products/writeOut"} style={{ width: "100%" }}
                        ><FontAwesomeIcon icon={faMinusSquare} /> Изписване</Link></Col>
                    }
                    {isAuthorized('warehouse/products/add') &&
                        <Col style={{marginTop: "5px" }} sm="2"><Link className="btn btn-success" to={"/products/add"} style={{ width: "100%" }}
                        ><FontAwesomeIcon icon={faPlusSquare} /> Добавяне</Link></Col>
                    }
                    {isAuthorized('warehouse/products/overhaul') &&
                        <Col style={{marginTop: "5px" }} sm="2"><Link className="btn btn-info" to={"/products/overhaul"} style={{ width: "100%" }}
                        ><FontAwesomeIcon icon={faClipboardCheck} /> Ревизия</Link></Col>
                    }

                    {products &&
                        <>
                            <Col sm="3"></Col>
                            <Col sm="1" style={{marginTop: "5px" }}>
                                <CSVLink sm="1" className="btn btn-info btn-sm form-control pull-right" data={setGetExportData(products)}
                                    filename={"products.csv"}><FontAwesomeIcon icon={faFileExport} /></CSVLink></Col>
                        </>
                    }
                </Form.Row>
                <Form.Row style={{marginTop: "15px" }}><Col sm="12">
                    <SearchProductField allValues={allProducts} callback={setProducts} />
                </Col></Form.Row>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                    <colgroup>
                        <col span={4} />
                        <col span={1} style={{ width: "5%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th>Barcode</th>
                            <th>Цена</th>
                            <th>Налично количество</th>
                            <th>Активен</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products?.map((p) => (
                            <tr key={p.id}>
                                <td>{p.name}</td>
                                <td>{(p.barcode?.length < 15 || p.barcode === null || p.barcode === undefined) ? p.barcode : p.barcode?.substring(0, 15) + "... "}</td>
                                <td>{p.priceSell}</td>
                                <td style={(p.quantity < 1) ? { color: 'red' } : {}}>{p.quantity}</td>
                                <td>{p.active ? <span style={{ color: 'lime' }}>Да</span> : <span style={{ color: 'red' }}>Не</span>}</td>
                                <td>
                                    {isAuthorized('warehouse/products/edit') &&
                                        <Link className="dpool-action-link" to={"/products/edit"} state={{ prod: p }} >
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Link>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>

        </Card>
    </>)
}

export default ProductList
