import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Col, Form, Table } from 'react-bootstrap';
import { confirm } from "react-confirm-box";
import { toast } from 'react-toastify';
import { getData, sendHttpRequest } from '../../utils/apiUtils';
import { utcToLocalDateTime } from '../../utils/dateUtils';
import { USER_KEY } from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';
import { ALL_PRODUCTS_KEY } from '../warehouse/CashRegister';

class CashdeskReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qParam: {
                from: new Date().toISOString().slice(0, 10) + "T00:00:00.000000Z",
                to: new Date().toISOString().slice(0, 10) + "T23:59:59.999999Z"
            },
            totalCash: 0,
            totalNoCash: 0,
            processing: false,
        }

        this.cashdesk = {}
        this.user = JSON.parse(sessionStorage.getItem(USER_KEY));
    }

    componentDidMount() {
        this.refreshData()
    }

    async refreshData(e) {
        this.setState({ qResultCash: undefined, qResultNoCash: undefined, qResultTransactions: undefined, totalCash: undefined, totalNoCash: undefined })

        const cashData = await getData("/api/wrh/cashdesk/cashAmountForPeriod?from=" + this.state.qParam.from + "&to=" + this.state.qParam.to, this.user)
        const noCashData = await getData("/api/wrh/cashdesk/noCashAmountForPeriod?from=" + this.state.qParam.from + "&to=" + this.state.qParam.to, this.user)
        const transactions = await getData("/api/wrh/orders/forPeriod?from=" + this.state.qParam.from + "&to=" + this.state.qParam.to, this.user)

        this.setState({
            qResultCash: cashData, totalCash: cashData.reduce((total, arg) => total + arg.amount, 0),
            qResultNoCash: noCashData, totalNoCash: noCashData.reduce((total, arg) => total + arg.amount, 0),
            qResultTransactions: transactions,
        })
    }

    async revertTransaction(tr) {
        const result = await confirm("Сторниране на транзакция " + tr.id + " за сума " + tr.amount + " лв.")

        if (result) {
            console.log("You click yes!");
            if (this.processing) {
                return
            }
            this.setState({ processing: true })

            const reqBody = {
                paymentType: tr.paymentType,
                refOrderId: tr.id,
            }

            await sendHttpRequest("/api/wrh/orders/reversal", "POST", reqBody, this.user).then(response => {
                response.json().then(result => {
                    if (result?.status) {
                        toast.error(result?.message, { autoClose: false, onClose: (e) => { this.setState({ ...this.state, error: '' }) } })
                    } else {
                        toast.success('Операцията е завършена', {
                            onOpen: (e) => {
                                this.setState({ ...this.state, error: '' })
                                sessionStorage.removeItem(ALL_PRODUCTS_KEY)
                            },
                            autoClose: 3000
                        });

                        this.componentDidMount()
                    }

                    this.setState({ processing: false })
                })
            })
        } else {
            console.log("You click No!");
        }


    }

    render() {
        return (<>
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <Form.Row>
                        <Form.Group controlId="formGridPhone" as={Col}>
                            <Form.Label>От дата</Form.Label>
                            <Form.Control type="date" placeholder="Валидна от" name="activeFrom" value={this.state.qParam.from && new Date(this.state.qParam.from).toISOString().split("T")[0]}
                                onChange={(e) => this.setState({ ...this.state, qParam: { ...this.state.qParam, from: e.target.value + "T00:00:00.000000Z" } })}
                                required autoComplete="off" />
                        </Form.Group>
                        <Form.Group controlId="formGridAddress" as={Col}>
                            <Form.Label>До дата</Form.Label>
                            <Form.Control type="date" placeholder="Валидна до" name="activeTo" value={this.state.qParam.to && new Date(this.state.qParam.to).toISOString().split("T")[0]}
                                onChange={(e) => this.setState({ ...this.state, qParam: { ...this.state.qParam, to: e.target.value + "T23:59:59.999999Z" } })} required autoComplete="off" />
                        </Form.Group>
                        <Form.Group controlId="formGridRun" as={Col}>
                            <Form.Label>&nbsp;</Form.Label>
                            <ActionButton className="btn btn-success btn-sm form-control" onClick={this.refreshData.bind(this)}>Изпълни</ActionButton>
                        </Form.Group>
                    </Form.Row>
                </Card.Header>
                {this.state.qResultCash && <Card.Body>
                    <h4>В брой</h4>
                    <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Служител</th>
                                <th>Сума</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.qResultCash.map((data) => (
                                <tr key={data.date + "," + data.employee?.name + "," + data.amount}>
                                    <td>{data.date}</td>
                                    <td className="m-auto">{data.employee?.name}</td>
                                    <td>{data.amount.toFixed(2)} лв.</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <h4>Общо в брой: {this.state.totalCash.toFixed(2)}</h4>
                </Card.Body>}
                {this.state.qResultNoCash && <Card.Body>
                    <h4>Безкасово</h4>
                    <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Служител</th>
                                <th>Сума</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.qResultNoCash.map((data) => (
                                <tr key={data.date + "," + data.employee?.name + "," + data.amount}>
                                    <td>{data.date}</td>
                                    <td className="m-auto">{data.employee?.name}</td>
                                    <td>{data.amount.toFixed(2)} лв.</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <h4>Общо безкасово: {this.state.totalNoCash.toFixed(2)}</h4>
                </Card.Body>}

                {(this.state.qResultCash || this.state.qResultNoCash) && <Card.Body>
                    <h4>Общо: {(this.state.totalCash + this.state.totalNoCash).toFixed(2)} лв.</h4>
                </Card.Body>}
            </Card>
            {this.state.qResultTransactions && <Card className="border border-dark bg-dark-dpool text-white centerElement" style={{ marginTop: "10px" }}>
                <Card.Header>
                    <h4>Транзакции за периода</h4>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                        <thead>
                            <tr>
                                <th>Номер</th>
                                <th>Дата и час</th>
                                <th>Сума</th>
                                <th>Тип плащане</th>
                                <th>Бр. продукти</th>
                                <th>Служител</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.qResultTransactions.map((data) => (
                                <tr key={"order_" + data.id}>
                                    <td>{data.id}</td>
                                    <td>{utcToLocalDateTime(data.dateCreated)}</td>
                                    <td>{data.amount?.toFixed(2)} </td>
                                    <td>
                                        {data.paymentType.name}
                                        {data.orderType === 3 && <> (Сторно)</>}
                                    </td>
                                    <td>{data.productsCount}</td>
                                    <td>{data.createdBy.name}</td>
                                    <td>
                                        {data.canBeReversed &&
                                            <ActionButton size="sm" variant="danger" title="Сторниране" onClick={(e) => { return this.revertTransaction.bind(this)(data) }}>
                                                <FontAwesomeIcon icon={faUndoAlt} />
                                            </ActionButton>
                                        }
                                        {data.canBeReversed === false &&
                                            data.referenceOrder
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>}
        </>);
    }
}

CashdeskReport.propTypes = {

};

export default CashdeskReport;