
/* eslint-disable import/no-anonymous-default-export, no-extend-native */

export default {}

export function cardActivityLog_ActionTranslate(cal_action) {

    switch (cal_action) {
        case 'CARD_ADDED': return 'Добавяне'
        case 'CARD_CHECKED': return 'Маркиране'
        case 'CARD_REPLACED': return 'Замяна'
        case 'CARD_UNCHECKED': return 'ОтМаркиране'
        case 'CARD_UPDATED': return 'Презареждане / Промяна'

        default:
            return 'Не разпознато действие: ' + cal_action

    }
}

export function cardActivityLog_MessageTranslate(cal_message) {
    var result = cal_message
    result = result.replace(/(, )/g, '\n')
    result = result.replace(/(Tickets)/g, 'Посещения: ')
    result = result.replace(/(Location)/g, 'Услуга: ')
    result = result.replace(/(ActiveFrom)/g, 'Валидна от: ')
    result = result.replace(/(ActiveTo)/g, 'Валидна до: ')


    return result
}
