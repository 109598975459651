import { useState } from 'react';

export const USER_KEY = 'user';

export default function useUser() {
  const getUser = () => {
    const userString = sessionStorage.getItem(USER_KEY);
    const user = JSON.parse(userString);
    return user;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = (user) => {
    if (user === null || user === undefined) {
      sessionStorage.removeItem(USER_KEY)
    } else {
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    setUser(user);
  };

  const isAuthorized = (component) => {

    const requiredPermission = "ui/" + component

    if (user?.permissions?.includes(requiredPermission)) {
      return true;
    } else if (user?.permissions !== undefined && !user?.permissions?.includes(requiredPermission)) {
      console.log("Not authorized for " + requiredPermission)
    }

    return false;
  };

  return {
    setUser: saveUser,
    isAuthorized,
    user
  }
}