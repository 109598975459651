/* eslint-disable import/no-anonymous-default-export, no-extend-native */

export default {}

Date.prototype.setTimezoneOffset = function (timezoneOffset) {
    return this.timezoneOffset = timezoneOffset;
};

export function utcToLocalDateTime(dateString) {

    if (dateString !== undefined) {
        let dt = new Date(dateString);
        let result = dt.toLocaleString('sv')

        return result;
    }
}

export function utcToLocalDate(dateString) {

    if (dateString !== undefined) {
        let result = utcToLocalDateTime(dateString).split(" ")[0]

        return result;
    }
}

export function localToUtcDateTime(dateString) {
    if (dateString !== undefined) {
        let dt = new Date(dateString);
        let result = dt.toLocaleString('sv', { timeZone: 'UTC' }) + "Z"

        return result;
    }
}

export function localToUtcDate(dateString) {
    if (dateString !== undefined) {
        let result = localToUtcDateTime(dateString).split(" ")[0]

        return result;
    }
}
