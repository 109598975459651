
import { toast } from 'react-toastify';
import packageJson from '../../package.json';

/* eslint-disable import/no-anonymous-default-export */

export async function sendHttpRequest(url, method, entity, user, timeout = 60000) {

    const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

    if (user !== undefined && user !== null) {
        HEADERS.Authorization = 'Bearer ' + user.jwt
    }

    const requestInit = {
        headers: HEADERS,
        method: method,
        credentials: 'include',
        timeout: timeout,
    };

    if (entity !== undefined && entity !== null) {
        if (typeof (entity) === 'object') {
            requestInit.body = JSON.stringify(entity);
        } else {
            requestInit.body = entity;
        }
    }

    const URL = (process.env.REACT_APP_API_URL || '') + url;

    try {
        var response  = await fetchWithTimeout(encodeURI(URL), requestInit)
  
        switch (response?.status) {
            case 401:
                window.location.href = "/logout"
                break;
            default:
        }

        // console.log(response);

        return response;
    } catch(error) {
        toast.error(error.message, { autoClose: false, toastId: error.message })
        throw error
    }
}

async function fetchWithTimeout(resource, options = {}) {
    const { timeout } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);

    return response;
  } 

export async function getData(url, user, callback) {
    return sendHttpRequest(url, "GET", null, user).then(async response => {
        return response.json().then(data => {
            if (callback !== undefined)
                callback(data);
            else
                return data;
        })
    })
}

export async function getCodeTable(url, user, callback) {
    return getData(url, user).then(async data => {
        let opts = []
        data.forEach(d => {
            opts.push({ ...d, value: d.id, label: d.name })
        })
        if (callback !== undefined)
            callback(opts);
        else
            return opts;
    })
}

export async function storeDatabseEntity(entity, baseUrl, user, onError, onSuccess) {

    const isUpdating = Object.prototype.hasOwnProperty.call(entity, 'id') && (entity?.id !== undefined);
    const URL = "/api/" + baseUrl + (isUpdating ? ("/update/" + entity.id) : "/create");
    const METHOD = isUpdating ? "PUT" : "POST"
    const response = await sendHttpRequest(URL, METHOD, entity, user);
    const result = await response.json();
    if (result?.status) {
        onError(result?.message)
    } else {
        if (result?.id) {
            onSuccess("Записът е успешен");
        } else {
            onError("Неуспешен запис");
        }
    }
}

export function handleKeyPress(e, data) {

    if (e.keyCode === 35 // End
        || e.keyCode === 36 // Home
        || e.keyCode === 45 // Insert
        || e.keyCode === 46 // Delete
    ) {
        // e?.preventDefault();
        return data;
    }
    const digits = '0123456789'
    const special = '._/=+{}[]()'
    const lettersEn = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const lettersBg = 'АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪѝЮЯабвгдежзийклмнопрстуфхцчшщъьюя'

    const allowedChars = "".concat(digits, special, lettersEn, lettersBg)

    switch (e.key) {
        case 'Control': case 'Shift': case 'Alt': case 'ArrowLeft': case 'ArrowRight':
        case 'F1': case 'F2': case 'F3': case 'F4': case 'F5': case 'F6':
        case 'F7': case 'F8': case 'F9': case 'F10': case 'F11': case 'F12':
        case 'Enter':
            break
        case 'Backspace':
            e?.preventDefault();
            data = data?.slice(0, -1)
            break
        default:
            if (allowedChars.includes(e.key)) {
                e?.preventDefault();
                data += (e.key || String.fromCharCode(e.key))
            }
            break
    }

    return data;
}

export function getAppVersion() {
    return packageJson.version;
}

export function sleep(milis) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milis);
}

export default {}