import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function LoadingOverlay({ ...props }) {
    return (
        <div style={{
            display: props.show ? "block" : "none",
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            backgroundColor: "rgba(230, 230, 230, 0.8)",
            zIndex: 999,
        }}>
            <FontAwesomeIcon
                icon={faSpinner}
                pulse
                style={{
                    position: "fixed",
                    display: "block",
                    top: "50%",
                    left: "50%",
                    color: "#308e9c",
                }}
                size="9x" />
        </div>
    );
}

export default LoadingOverlay;