import { Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';

const NavigationBar = () => {

    const { user } = useUser();
    const location = useLocation();

    const getTitleText = (href) => {
        let partial = href.split('/')[1]
        if (partial) {
            switch (partial) {
                case 'checkcard':
                    return 'Маркиране'
                case 'cashregister':
                    return 'Каса'
                case 'products':
                    return 'Продукти'
                case 'customers':
                    return 'Клиенти';
                case 'employees':
                    return 'Служители'
                case 'config':
                    return 'Настройки'
                case 'login':
                    return 'Вход'
                case 'reports':
                    return 'Справки'
                default:
                    return partial;
            }
        } else {
            return "Начало"
        }
    }



    return (<>
        <Navbar bg="dpool" variant="light" style={{ height: '55px' }}>
            <div style={{ float: 'left', margin: '-10px auto -10px -15px' }}>
                <Link to={"/"} className="navbar-brand" >
                    <img src="/logo-white-transparent.png" width="50" height="50" alt="DPool" />
                </Link>
            </div>
            <div style={{ float: 'right', margin: '-10px 0px -10px auto' }}>
                <img src="/iv_white.png" width="50" height="50" alt="by IV" />
            </div>
            {user?.username &&
                getTitleText(location.pathname) &&
                <Nav className="dpool-title" >
                    <h2>{getTitleText(location.pathname)}</h2>
                </Nav>
            }
        </Navbar>
    </>)
}

export default NavigationBar
