import { faCamera, faSave, faSignInAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useRef, useState } from "react"
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import Webcam from "react-webcam"
import profilePicture from "../../images/profilePicture.png"
import { storeDatabseEntity } from '../../utils/apiUtils'
import useUser from '../hooks/useUser'
import ActionButton from "../layout/ActionButton"

function Customer() {

    const location = useLocation();
    const history = useNavigate();

    const webcamRef = useRef(null);

    const [showCameraModal, setShowCameraModal] = useState(false);

    let cust = location?.state?.cust || {};
    let searchKeyword = location?.state?.searchKeyword;

    const [customer, setCustomer] = useState({ ...cust })
    const [error, setError] = useState('')
    const { user } = useUser();

    const onSubmit = async (e) => {

        e.preventDefault();

        if (error !== '') {
            return;
        }

        const reqBody = {
            id: customer.id,
            firstName: customer.firstName,
            phone: customer.phone,
            emailAddress: customer.emailAddress,
            photo: customer.photo,
            comment: customer.comment,
        }

        await storeDatabseEntity(reqBody, 'customers', user,
            (e) => { // onError 
                setError(e);
                toast.error(e, { autoClose: false, onClose: (e) => { setError('') } })
            },
            () => { // onSuccess
                toast.success('Клиентът е запазен', {
                    onOpen: (e) => { setError(''); },
                    autoClose: 3000
                });
                history('/customers', { state: { searchKeyword: searchKeyword || customer.phone } });
            })
    }

    const formatDate = (dateStr) => {
        return new Date(dateStr).toLocaleString('bg-BG');
    }

    const getUserTimeInfo = (userStr, dateStr) => {
        return userStr + " (" + formatDate(customer.dateCreated) + ")"
    }

    const onCameraModalClose = () => {
        setShowCameraModal(false);
    }

    const onClickPhoto = () => {
        setShowCameraModal(true);
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 200, height: 150 });
        customer.photo = imageSrc;
        onCameraModalClose();
    }, [webcamRef, customer]);

    return (<>
        <Modal className="text-dark" show={showCameraModal} onHide={onCameraModalClose}>
            <Modal.Header closeButton >
                <Modal.Title>Камера</Modal.Title>
            </Modal.Header>

            <Webcam ref={webcamRef} height={150} width={200} className="my-3 mx-auto" style={{ border: "3px", borderColor: "#145b66", borderStyle: "solid" }} />
            <Button variant="dpool" className="mb-3 mx-auto" type="button" onClick={capture}><FontAwesomeIcon icon={faCamera} /> Снимай</Button>

        </Modal>

        {((location?.state?.cust && location?.pathname === '/customers/edit') || (!location?.state?.cust && location?.pathname === '/customers/add')) ?
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <FontAwesomeIcon icon={faSignInAlt} /> {customer?.id ? ('Редактиране на клиент: ' + customer.name) : 'Добавяне на клиент'}
                    <span style={{ float: "right" }}>
                        <ActionButton size="sm" variant="success" type="submit" onClick={onSubmit}><FontAwesomeIcon icon={faSave} /> Запази</ActionButton>
                        &nbsp;
                        <ActionButton size="sm" variant="danger" type="button"
                            onClick={(e) => { history('/customers', { state: { searchKeyword: searchKeyword } }) }}>Откажи <FontAwesomeIcon icon={faTimesCircle} /></ActionButton>
                    </span>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={onSubmit} id="id">

                        <Image className="my-3 profilePicture" src={customer.photo || profilePicture} rounded onClick={onClickPhoto} width={200} height={150} />

                        <Row className="my-3">
                            <Form.Label column sm="2" >Име</Form.Label>
                            <Col sm="10" style={{ padding: "0px" }}>
                                <Form.Control type="text" placeholder="Име" name="firstName" value={customer.firstName}
                                    onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })} required autoComplete="off" style={{ display: "flex" }} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Телефон</Form.Label>
                            <Col sm="10" style={{ padding: "0px" }}>
                                <Form.Control type="text" placeholder="Телефон" name="phone" value={customer.phone}
                                    onChange={(e) => setCustomer({ ...customer, phone: e.target.value })} required autoComplete="off" />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Email Адрес</Form.Label>
                            <Col sm="10" style={{ padding: "0px" }}>
                                <Form.Control type="email" placeholder="Email Адрес" name="emailAddress" value={customer.emailAddress}
                                    onChange={(e) => setCustomer({ ...customer, emailAddress: e.target.value })} autoComplete="off" />
                            </Col>
                        </Row>

                        <Form.Row className="my-3">
                            <Col sm="12">
                                <Form.Label column sm="2" style={{ padding: "0px 0px 7px 0px" }} >Коментар</Form.Label>
                                <Form.Control as="textarea" placeholder="" name="comment" value={customer.comment} rows={3}
                                    onChange={(e) => setCustomer({ ...customer, comment: e.target.value })} autoComplete="off" />
                            </Col>
                        </Form.Row>

                        {customer.id && <>
                            <Form.Row>
                                <Form.Group controlId="formGridAddedOn" as={Col}>
                                    <Form.Label>Добавен от (на)</Form.Label>
                                    <Form.Control type="text" name="createdBy" value={getUserTimeInfo(customer.createdBy.name, customer.dateCreated)} disabled autoComplete="off" />
                                </Form.Group>
                                <Form.Group controlId="formGridAddedBy" as={Col}>
                                    <Form.Label>Последно редактиран на</Form.Label>
                                    <Form.Control type="text" name="updatedBy" value={getUserTimeInfo(customer.updatedBy.name, customer.dateUpdated)} disabled autoComplete="off" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group controlId="formGridAddedBy">
                                <Form.Label>Регистрирани карти</Form.Label>
                                <Form.Control type="text" name="countCards" value={customer.cards?.length ? customer.cards.length : 0} disabled autoComplete="off" />
                            </Form.Group>
                        </>}
                    </Form>
                </Card.Body>
            </Card>
            : <Navigate to={{ pathname: "/customers" }} />}
    </>)
}

export default Customer
