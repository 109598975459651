import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const CardList = ({ list, customer, searchKeyword }) => {

    return (<>
        {list?.length > 0 ?
            <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                <colgroup>
                    <col span={7} />
                    <col span={1} style={{ width: "5%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Номер</th>
                        <th>Услуга</th>
                        <th>Тип</th>
                        <th>Валидна от</th>
                        <th>Валидна до</th>
                        <th>Посещения</th>
                        <th>Последно обновена</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map(card => (
                            <tr key={card.identifier + '_' + card.location.id}>
                                <td>{card.identifier}</td>
                                <td>{card.location.name}</td>
                                <td>{card.type.name}</td>
                                <td>{new Date(card.activeFrom).toLocalDate()}</td>
                                <td>{new Date(card.activeTo).toLocalDate()}</td>
                                <td>{!card.timeCard && card.ticketsCount}</td>
                                <td>{new Date(card.dateUpdated).toLocalString()}</td>
                                <td>
                                    <Link className="dpool-action-link" to={"/customers/card/edit"} state={{ card: { ...card, customer: customer }, searchKeyword: searchKeyword }}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            : <span style={{ color: 'orange' }}>Няма регистрирани карти</span>
        }
    </>)
}

export default CardList
