import { faKey, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { sendHttpRequest } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';

function ChangePassword() {

    const { user } = useUser();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('') // eslint-disable-line no-unused-vars

    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })

        let curPassField = e.target.form.currentPassword;
        let newPassField = e.target.form.newPassword;
        let repPassField = e.target.form.repeatedPassword;

        const validateField = (field) => {

            const validateFieldInternal = (f) => {
                let value = f.value;
                let name = f.name;
                // validate any related to the current field
                switch (name) {
                    case 'currentPassword':
                        field.className = 'form-control is-valid';
                        break;
                    case 'newPassword':
                        if (value === curPassField.value) {
                            field.className = 'form-control is-invalid';
                        } else if (repPassField.value?.legth > 0 && value !== repPassField.value) {
                            field.className = 'form-control is-invalid';
                        } else {
                            field.className = 'form-control is-valid';
                        }
                        break;
                    case 'repeatedPassword':
                        if (newPassField.value?.length > 0 && value !== newPassField.value) {
                            field.className = 'form-control is-invalid';
                        } else {
                            field.className = 'form-control is-valid';
                        }
                        break;
                    default:
                }
            }

            let value = field.value;

            // Only check legth if currently editing field
            let isCurentlyEdited = (e.target === field);

            if (isCurentlyEdited) {
                if (value?.length === 0) {
                    field.className = 'form-control is-invalid';
                } else {
                    validateFieldInternal(field);
                }
            } else {
                if (value?.length > 0) {
                    validateFieldInternal(field);
                }
            }
        }

        validateField(e.target);

        switch (e.target.name) {
            case 'currentPassword':
                validateField(newPassField);
                validateField(repPassField);
                break;
            case 'newPassword':
                validateField(repPassField);
                break;
            case 'repeatedPassword':
                break;
            default:
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setValidated(true);

        const form = e.target.form; // e.currentTarget
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            const userJson = {
                username: user.username,
                password: formData.newPassword
            };

            const response = await sendHttpRequest("/api/employees/changePassword", "POST", userJson, user)

            if (response.status === 200) {
                toast.success("Паролата е променена успешно", { onClose: () => { window.location.href = "/" }, toastId: 'success' });
            } else {
                let err = (await response.json()).error;

                setError(err);
                let errorMsg;
                switch (err) {
                    case "same.password":
                        errorMsg = "Старата парола не може да съвпада с новата";
                        break;
                    case "blank.password":
                        errorMsg = "Новата парола не може да е празна";
                        break;
                    case "user.not.matching":
                        errorMsg = "Можете да сменяте само собствената си парола";
                        break;
                    default:
                        errorMsg = err;
                }
                toast.error(errorMsg, { toastId: 'error' });
            }
        }
    }

    return (<>
        <Form noValidate validated={validated} onSubmit={onSubmit} id="id">
            <Card style={{ maxWidth: "36rem" }} className="border border-dark bg-dark-dpool text-white centerElement">
                <Card.Header>
                    <FontAwesomeIcon icon={faKey} /> Промяна на парола
                    <ActionButton size="sm" variant="success" type="submit" onClick={onSubmit} style={{ float: "right" }}>
                        <FontAwesomeIcon icon={faSave} /> Запази
                    </ActionButton>
                </Card.Header>
                <Card.Body>
                    <Form.Group controlId="formGridPassword" validated={false}>
                        <Form.Label>Текуща парола</Form.Label>
                        <Form.Control type="password" placeholder="Текуща парола" name="currentPassword" value={formData.currentPassword}
                            onChange={onInputChange} required autoComplete="off" />
                        <Form.Control.Feedback type="invalid">
                            Моля въведете текуща парола
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formGridNewPassword">
                        <Form.Label>Нова парола</Form.Label>
                        <Form.Control type="password" placeholder="Нова парола" name="newPassword" value={formData.newPssword}
                            onChange={onInputChange} required autoComplete="off" />
                        <Form.Control.Feedback type="invalid" >
                            Новата парола не може да е празна или да съвпада с текущата парола
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formGridRepeatedPassword">
                        <Form.Label>Повторете новата парола</Form.Label>
                        <Form.Control type="password" placeholder="Повторете паролата" name="repeatedPassword" value={formData.repeatedPassword}
                            onChange={onInputChange} required autoComplete="off" />
                        <Form.Control.Feedback type="invalid">
                            Паролите не съвпадат
                        </Form.Control.Feedback>
                    </Form.Group>
                </Card.Body>
            </Card>
        </Form>
    </>)
}

export default ChangePassword
