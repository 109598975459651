import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getData } from '../../utils/apiUtils';
import { utcToLocalDateTime } from '../../utils/dateUtils';
import { cardActivityLog_ActionTranslate, cardActivityLog_MessageTranslate } from '../../utils/enumUtils';
import useUser from '../hooks/useUser';

const CardActivityLog = ({ card }) => {

    const [cardActivityLog, setCardActivityLog] = useState([]);
    const { user } = useUser();

    useEffect(() => {
        getData("/api/cards/activity/" + card.id, user, setCardActivityLog)
    }, [card, user])

    return (<>
        <span>История</span>
        <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
            <thead>
                <tr key={'thead'}>
                    <th>Дата</th>
                    <th>Действие</th>
                    <th>Детайли</th>
                    <th>Служител</th>
                </tr>
            </thead>
            <tbody>
                {
                    cardActivityLog.map((log, key) => (<>
                        <tr key={key} >
                            <td>{utcToLocalDateTime(log.dateCreated)}</td>
                            <td>{cardActivityLog_ActionTranslate(log.action)}</td>
                            <td style={{ 'white-space': 'pre' }} >{cardActivityLog_MessageTranslate(log.message)}</td>
                            <td>{log.createdBy.name}</td>
                        </tr>
                    </>))
                }
            </tbody>
        </Table>
    </>);
};

export default CardActivityLog;