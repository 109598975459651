import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const ActionButton = ({ id = (Math.random().toString(36) + '00000000000000000').slice(2, 7), title, action, ...props }) => {

    const onAction = async (e) => {

        const loadingSpinner = document.getElementById(id + "_loadingSpinner")
        if (loadingSpinner.style.display === "inline") {
            return
        }
        loadingSpinner.style.display = "inline"

        try {
            await props.onClick(e)
        } finally {
            loadingSpinner.style.display = "none"
        }
    }

    return (
        <Button id={id} {...props} onClick={onAction} >
            {props.children}
            &nbsp;<FontAwesomeIcon id={id + "_loadingSpinner"} icon={faSpinner} pulse style={{ display: "none" }} />
        </Button>
    );
};

export default ActionButton;