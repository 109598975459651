import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { sendHttpRequest } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';

function CLastActions() {

    const { user } = useUser();
    const [error, setError] = useState('')

    const showError = (msg) => {
        setError(msg);
        toast.error(
            msg,
            { autoClose: false, onClose: (e) => { setError('') } }
        );
    }

    const customerActionsList = useState(async () => { // eslint-disable-line no-unused-vars
        const response = await sendHttpRequest("/api/customers/list", "GET", null, user)
        const jsonData = await response.json();
        if (response?.status === 403) {
            if (!error) {
                showError('Нямате достъп за желаната операция')
            } else {
                showError("Грешка: " + error)
            }
        } else {
            return jsonData;
        }
    });


    return (
        <Card className="border border-dark bg-dark-dpool text-white centerElement">
            <Card.Header className="center">Last Actions</Card.Header>
        </Card>
    )
}

export default CLastActions
