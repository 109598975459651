import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table } from 'react-bootstrap';


const CurrentOrder = ({ selectedProducts, onAddClickHandler, onRemoveClickHandler }) => {

    const getTotal = () => {
        var total = 0
        selectedProducts.forEach((v, k) => {
            total += (v.prd.priceSell * v.qty)
        })
        return total.toFixed(2)
    }

    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
            <Card.Header className="left" style={{ cursor: 'pointer' }}>

            </Card.Header>
            <Card.Body>
                <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th style={{ width: "40px" }}>Цена</th>
                            <th style={{ width: "50px" }}>Количество</th>
                            <th style={{ width: "50px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedProducts.size === - 0 &&
                            <tr><td colSpan={4}>Моля, изберете продукт</td></tr>
                        }
                        {Array.from(selectedProducts.entries()).map((p) => (
                            <tr key={p[1].prd.id} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onRemoveClickHandler(p[1].prd) }} >
                                <td>{p[1].prd.name}</td>
                                <td>{p[1].prd.priceSell}</td>
                                <td>{p[1].qty}</td>
                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                    <FontAwesomeIcon icon={faPlusSquare} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onAddClickHandler(p[1].prd) }} />
                                    <br />
                                    <FontAwesomeIcon icon={faMinusSquare} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onRemoveClickHandler(p[1].prd) }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h5 style={{ "float": "right", margin: "20px 20px -10px 0px", fontWeight: "bold", fontSize: "25px" }}>Тотал: {getTotal()}</h5>
            </Card.Body>
        </Card>
    </>)
}

export default CurrentOrder