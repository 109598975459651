import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Accordion, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendHttpRequest } from '../../utils/apiUtils';
import useUser from '../hooks/useUser';

const EmployeeList = () => {

    const [employees, setEmployees] = useState([]);

    const { user } = useUser();
    const [error, setError] = useState('')

    const showError = (msg) => {
        setError(msg);
        toast.error(
            msg,
            { autoClose: false, onClose: (e) => { setError('') } }
        );
    }

    useEffect(() => {
        sendHttpRequest("/api/employees/all", "GET", null, user)
            .then(response => {
                response.json().then(employeesResponse => {
                    if (response?.status === 403) {
                        if (!error) {
                            showError('Нямате достъп за желаната операция')
                        } else {
                            showError("Грешка: " + error)
                        }
                    } else {
                        setEmployees(employeesResponse);
                    }
                })

            })
    }, [error, user]);

    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key='employees' style={{ margin: "10px 0px 10px 0px" }}>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey='employees'>
                <Card.Header className="left" style={{ cursor: 'pointer' }}>
                    <h3>
                        <span>Служители</span>
                        <span style={{ float: "right" }}>
                            <Accordion.Collapse eventKey='employees'>
                                <Link className="btn btn-dpool btn-sm" to={"/config/employees/add"} >Добави</Link>
                            </Accordion.Collapse>
                        </span>
                    </h3>
                </Card.Header>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='employees'>
                <Card.Body>

                    <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                        <colgroup>
                            <col span={4} />
                            <col span={1} style={{ width: "5%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Име</th>
                                <th>Потребител</th>
                                <th>Роля</th>
                                <th>Активен</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((e) => (
                                <tr key={e.id}>
                                    <td>{e.name}</td>
                                    <td>{e.username}</td>
                                    <td>{e.role.name}</td>
                                    <td>{e.active ? <span style={{ color: 'lime' }}>Да</span> : <span style={{ color: 'red' }}>Не</span>}</td>
                                    <td>
                                        <Link className="dpool-action-link" to={"/config/employees/edit"} state={{ employee: e }} >
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    </>)
}

export default EmployeeList
